import * as contactsActions from 'store/contacts/actions';
import { useDebounce } from 'hooks/use-debounce.hook';
import { usePrevious } from 'hooks/use-previous.hook';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTeammates,
  selectTeammatesAreLoading,
  selectTeammatesPaginationLimit,
  selectTeammatesPaginationOffset,
  selectTeammatesPaginationTotal,
} from 'store/contacts/selectors';
import { selectCurrentUserUuid } from 'store/user/selectors';
import { UserContact } from 'store/contacts/types';
import qs from 'qs';
import { CalendarQueryParams, CurrentModalView } from 'pages/calendar/types';
import { push } from 'store/router/actions';

interface Props {
  searchValue: string;
}

interface GoToUserRegistrations {
  userUuid: string;
  firstName: string;
  lastName: string;
}

export interface UseTeammatesResponse {
  addContact: (contact: UserContact) => () => void;
  deleteContact: (contact: UserContact) => () => void;
  getTeammates: (isScrolling?: boolean, resetOffset?: boolean) => void;
  goToUserRegistrations: (args: GoToUserRegistrations) => () => void;
  infiniteScrollCallback: () => void;
  isInitialLoad: boolean;
  isResetting: boolean;
  teammates: UserContact[];
  teammatesAreLoading: boolean;
  total: number;
  debouncedSearchValue: string;
}

export const useTeammates = ({ searchValue }: Props): UseTeammatesResponse => {
  const dispatch: (payload: unknown) => void = useDispatch();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const currentUserUuid: string = useSelector(selectCurrentUserUuid);
  const teammatesAreLoading: boolean = useSelector(selectTeammatesAreLoading);
  const teammates: UserContact[] = useSelector(selectTeammates);
  const limit: number = useSelector(selectTeammatesPaginationLimit);
  const offset: number = useSelector(selectTeammatesPaginationOffset);
  const total: number = useSelector(selectTeammatesPaginationTotal);
  const debouncedSearchValue: string = useDebounce(searchValue);
  const previousDebouncedSearchValue: string = usePrevious(debouncedSearchValue);

  const getTeammates = useCallback(
    (isScrolling = false, resetOffset = false) => {
      dispatch(
        contactsActions.getTeammates.request({
          user_uuid: currentUserUuid,
          offset: offset === null || resetOffset ? 0 : offset + limit,
          limit,
          sort: 'first_name',
          isScrolling,
          full_name: debouncedSearchValue,
        }),
      );
      if (resetOffset) {
        setIsResetting(true);
      }
    },
    [limit, offset, debouncedSearchValue, currentUserUuid],
  );

  const addContact = useCallback(
    (contact: UserContact) => () => {
      dispatch(contactsActions.createContacts.request({ user_uuid: currentUserUuid, contact_uuid: contact.uuid }));
    },
    [],
  );

  const deleteContact = useCallback(
    (contact: UserContact) => () => {
      dispatch(contactsActions.deleteContact.request({ user_uuid: currentUserUuid, contact_uuid: contact.uuid }));
    },
    [],
  );

  const goToUserRegistrations = useCallback(
    ({ userUuid, firstName, lastName }: GoToUserRegistrations) =>
      () => {
        const params = qs.stringify(
          {
            [CalendarQueryParams.UserUuid]: userUuid,
            [CalendarQueryParams.FirstName]: firstName,
            [CalendarQueryParams.LastName]: lastName,
            [CalendarQueryParams.CurrentModalView]: CurrentModalView.RegistrationsList,
            [CalendarQueryParams.ShowBackButton]: true,
          },
          {
            skipNulls: true,
            arrayFormat: 'comma',
            addQueryPrefix: true,
            encode: false,
          },
        );
        dispatch(push(`/calendar${params}`));
      },
    [],
  );

  const infiniteScrollCallback = useCallback(() => getTeammates(true), [getTeammates]);

  useEffect(() => {
    contactsActions.resetContacts();
  }, []);

  useEffect(() => {
    if (debouncedSearchValue !== null && previousDebouncedSearchValue !== debouncedSearchValue) {
      getTeammates(false, true);
    }
  }, [previousDebouncedSearchValue, debouncedSearchValue]);

  useEffect(() => {
    if (isInitialLoad && !teammatesAreLoading) {
      setIsInitialLoad(false);
    }
  }, [teammatesAreLoading]);

  useEffect(() => {
    if (!teammatesAreLoading && isResetting) {
      setIsResetting(false);
    }
  }, [isResetting, teammatesAreLoading]);

  return {
    addContact,
    deleteContact,
    getTeammates,
    isInitialLoad,
    isResetting,
    teammates,
    teammatesAreLoading,
    total,
    goToUserRegistrations,
    debouncedSearchValue,
    infiniteScrollCallback,
  };
};
