import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  ResetTeammateRegistrationPayload,
  GetUserRegistrationsPayload,
  GetUserRegistrationsSuccessPayload,
  GetUserRegistrationsFailurePayload,
  CreateRegistrationsPayload,
  CreateRegistrationsSuccessPayload,
  CreateRegistrationsFailurePayload,
  DeleteRegistrationPayload,
  DeleteRegistrationSuccessPayload,
  DeleteRegistrationFailurePayload,
  UpdateRegistrationsPayload,
  UpdateRegistrationsSuccessPayload,
  UpdateRegistrationsFailurePayload,
  TeammateRegistrationFailurePayload,
  TeammateRegistrationPayload,
  TeammateRegistrationSuccessPayload,
} from './types';

export const getUserRegistrations = createAsyncAction(
  'GET_REGISTRATIONS_REQUEST',
  'GET_REGISTRATIONS_SUCCESS',
  'GET_REGISTRATIONS_FAILURE',
)<GetUserRegistrationsPayload, GetUserRegistrationsSuccessPayload, GetUserRegistrationsFailurePayload>();

export const getTeammateRegistrations = createAsyncAction(
  'GET_TEAMMATE_REGISTRATIONS_REQUEST',
  'GET_TEAMMATE_REGISTRATIONS_SUCCESS',
  'GET_TEAMMATE_REGISTRATIONS_FAILURE',
)<TeammateRegistrationPayload, TeammateRegistrationSuccessPayload, TeammateRegistrationFailurePayload>();

export const createRegistrations = createAsyncAction(
  'CREATE_REGISTRATIONS_REQUEST',
  'CREATE_REGISTRATIONS_SUCCESS',
  'CREATE_REGISTRATIONS_FAILURE',
)<CreateRegistrationsPayload, CreateRegistrationsSuccessPayload, CreateRegistrationsFailurePayload>();

export const updateRegistration = createAsyncAction(
  'UPDATE_REGISTRATIONS_REQUEST',
  'UPDATE_REGISTRATIONS_SUCCESS',
  'UPDATE_REGISTRATIONS_FAILURE',
)<UpdateRegistrationsPayload, UpdateRegistrationsSuccessPayload, UpdateRegistrationsFailurePayload>();

export const deleteRegistration = createAsyncAction(
  'DELETE_REGISTRATIONS_REQUEST',
  'DELETE_REGISTRATIONS_SUCCESS',
  'DELETE_REGISTRATIONS_FAILURE',
)<DeleteRegistrationPayload, DeleteRegistrationSuccessPayload, DeleteRegistrationFailurePayload>();

export const resetTeammateRegistrations =
  createAction('RESET_TEAMMATE_REGISTRATIONS')<ResetTeammateRegistrationPayload>();
