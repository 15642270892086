/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const Users: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
  >
    <rect width="20" height="20" rx="10" fill="currentColor" />
    <mask
      id="mask0_1844_12991"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="14"
      height="14"
    >
      <rect x="3" y="3" width="14" height="14" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1844_12991)">
      <path
        d="M3 13.5V12.5812C3 12.1535 3.21389 11.8107 3.64167 11.5528C4.06944 11.2954 4.63333 11.1667 5.33333 11.1667C5.45972 11.1667 5.57892 11.1715 5.69092 11.1812C5.80253 11.191 5.91181 11.2056 6.01875 11.225C5.88264 11.4194 5.78289 11.6285 5.7195 11.8521C5.6565 12.0757 5.625 12.3139 5.625 12.5667V13.5H3ZM6.5 13.5V12.5667C6.5 11.9347 6.82336 11.4218 7.47008 11.0278C8.11642 10.6343 8.95972 10.4375 10 10.4375C11.05 10.4375 11.8958 10.6343 12.5375 11.0278C13.1792 11.4218 13.5 11.9347 13.5 12.5667V13.5H6.5ZM14.375 13.5V12.5667C14.375 12.3139 14.341 12.0757 14.2729 11.8521C14.2049 11.6285 14.1076 11.4194 13.9812 11.225C14.0882 11.2056 14.1977 11.191 14.3097 11.1812C14.4213 11.1715 14.5403 11.1667 14.6667 11.1667C15.3667 11.1667 15.9306 11.2954 16.3583 11.5528C16.7861 11.8107 17 12.1535 17 12.5812V13.5H14.375ZM5.33333 10.5833C5.0125 10.5833 4.73794 10.469 4.50967 10.2403C4.281 10.0121 4.16667 9.7375 4.16667 9.41667C4.16667 9.09583 4.281 8.82108 4.50967 8.59242C4.73794 8.36414 5.0125 8.25 5.33333 8.25C5.65417 8.25 5.92872 8.36414 6.157 8.59242C6.38567 8.82108 6.5 9.09583 6.5 9.41667C6.5 9.7375 6.38567 10.0121 6.157 10.2403C5.92872 10.469 5.65417 10.5833 5.33333 10.5833ZM14.6667 10.5833C14.3458 10.5833 14.0711 10.469 13.8424 10.2403C13.6141 10.0121 13.5 9.7375 13.5 9.41667C13.5 9.09583 13.6141 8.82108 13.8424 8.59242C14.0711 8.36414 14.3458 8.25 14.6667 8.25C14.9875 8.25 15.2622 8.36414 15.4909 8.59242C15.7192 8.82108 15.8333 9.09583 15.8333 9.41667C15.8333 9.7375 15.7192 10.0121 15.4909 10.2403C15.2622 10.469 14.9875 10.5833 14.6667 10.5833ZM10 10C9.51389 10 9.10069 9.82986 8.76042 9.48958C8.42014 9.14931 8.25 8.73611 8.25 8.25C8.25 7.76389 8.42014 7.35069 8.76042 7.01042C9.10069 6.67014 9.51389 6.5 10 6.5C10.4861 6.5 10.8993 6.67014 11.2396 7.01042C11.5799 7.35069 11.75 7.76389 11.75 8.25C11.75 8.73611 11.5799 9.14931 11.2396 9.48958C10.8993 9.82986 10.4861 10 10 10Z"
        fill="white"
      />
    </g>
  </SvgIcon>
);

export default Users;
