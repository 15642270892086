import styled from 'styled-components';
import { EmptyState } from '@hqo/react-components-library/dist/molecules/empty-state';
import { Groups } from 'assets/icons/groups';
import { Calendar } from 'assets/icons/calendar';
import { SearchInput } from '@hqo/react-components-library/dist/search-input';
import PersonAdd from 'assets/icons/person-add';

export const SubTitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  margin-bottom: 16px;
`;

export const StyledEmptyState = styled(EmptyState)`
  margin-bottom: 32px;
`;

export const AvatarContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  width: 9.33px;
  height: 9.33px;
  padding: 2px;
  border-radius: 50%;
`;

export const GroupsIcon = styled(Groups)`
  color: ${({ theme }) => theme.colors.universal.white};
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 15px;
`;

export const LoaderWrapper = styled.div<{ fullHeight?: boolean }>`
  display: flex;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : '60px')};
  justify-content: center;
  align-items: center;
`;

export const Search = styled(SearchInput)`
  margin-top: 13px;
`;

export const StyledCalendarIcon = styled(Calendar)`
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const TeammatesWrapper = styled.div`
  margin-top: 24px;
  height: 100%;
`;

export const StyledAddPersonIcon = styled(PersonAdd)`
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;
