import { useRegistrationsList } from 'pages/calendar/registrations-list/use-registrations-list';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from 'store/config/selectors';
import { CancelRegistrationsDialog } from 'components/cancel-registrations-dialog/cancel-registrations-dialog';
import { Calendar as CalendarIcon } from 'assets/icons/calendar';
import { StyledButton, StyledEmptyState, StyledLoader } from './styles';
import { StatusPill } from '@hqo/react-components-library/dist/atoms/statusPill';
import { push } from 'store/router/actions';
import { CalendarQueryParams, CurrentModalView } from 'pages/calendar/types';
import qs from 'qs';
import { selectBrandTheme } from 'store/theme/selectors';
import { RegisterCalendarView } from 'components/register-calendar-view';
import { DisplayData } from '@hqo/react-components-library/dist/molecules/display-data';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from 'pages/calendar/tracking';

moment.tz.setDefault('Etc/UTC');

export const RegistrationsList = () => {
  const {
    userRegistrations,
    showCancelModal,
    handleShowCancelModal,
    handleHideCancelModal,
    cancellationRegistrationUuid,
    isLoading,
    isCurrentUser,
    userUuid,
    handleSelectedRegistration,
    currentRegistration,
    user,
  } = useRegistrationsList();
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const theme = useSelector(selectBrandTheme);
  const dispatch = useDispatch();

  const { handleTracking } = useTracking();

  useEffect(() => {
    if (isCurrentUser) {
      handleTracking(TrackingEvents.YourRegistrationsImpression, 'impression');
    } else {
      handleTracking(TrackingEvents.OtherRegistrationImpression, 'impression');
    }
  }, []);

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const goToDate = useCallback(
    (date: string) => () => {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const params = qs.stringify(
        {
          [CalendarQueryParams.UserUuid]: userUuid,
          [CalendarQueryParams.SelectedDate]: formattedDate,
          [CalendarQueryParams.CurrentModalView]: CurrentModalView.IndividualRegistrations,
          [CalendarQueryParams.ShowBackButton]: true,
        },
        {
          skipNulls: true,
          arrayFormat: 'comma',
          addQueryPrefix: true,
          encode: false,
        },
      );
      dispatch(push(`/calendar${params}`));
    },
    [userUuid],
  );

  const renderUserRegistrations = useMemo((): JSX.Element[] => {
    let registrations = [];
    if (isCurrentUser) {
      registrations = userRegistrations.filter((registration) => registration.uuid);
    } else {
      registrations = userRegistrations;
    }
    return registrations.map((registration) => {
      const date = moment(registration.date).format('dddd, LL');
      const teammateCount = registration.teammate_count ?? 0;
      const subtitle = intl.formatMessage({ id: 'teammatesGoing' }, { count: teammateCount });

      const selectCta = () => {
        if (isCurrentUser) {
          return (
            <StyledButton
              data-testid="cancel-registration-button"
              text={intl.formatMessage({ id: 'cancel' })}
              variant="primary"
              size="md"
              onClick={handleShowCancelModal(registration.uuid)}
            />
          );
        }
        const currentUserIsGoing = !!registration.user_uuid;
        if (currentUserIsGoing) {
          return <StatusPill status="Open" displayText={intl.formatMessage({ id: 'going' })} variant="rounded" />;
        }
        return (
          <StyledButton
            data-testid="register-button"
            text={intl.formatMessage({ id: 'register' })}
            variant="primary"
            size="md"
            onClick={handleSelectedRegistration(registration)}
          />
        );
      };
      const isActive = currentRegistration === registration.date;
      return (
        <DisplayData
          showShadow
          isActive={isActive}
          key={registration.date}
          dataTestId={`registration-${registration.date}`}
          title={date}
          subtitle={subtitle}
          element={selectCta()}
          onClick={goToDate(registration.date)}
        />
      );
    });
  }, [userRegistrations, intl, currentRegistration]);

  if (isLoading) {
    return <StyledLoader data-testid="loading-indicator" color={theme.primary_color} />;
  }

  return (
    <div>
      {renderUserRegistrations.length ? (
        renderUserRegistrations
      ) : (
        <StyledEmptyState
          dataTestId="empty-registrations-list"
          Icon={CalendarIcon}
          title={intl.formatMessage({ id: 'emptyYourRegistrations.title' })}
          description={
            user.uuid === userUuid
              ? [
                  intl.formatMessage({ id: 'emptyYourRegistrations.description1' }),
                  intl.formatMessage({ id: 'emptyYourRegistrations.description2' }),
                ]
              : [
                  intl.formatMessage({ id: 'emptyContactRegistrations.description1' }),
                  intl.formatMessage({ id: 'emptyContactRegistrations.description2' }),
                ]
          }
        />
      )}
      <CancelRegistrationsDialog
        registrationUuid={cancellationRegistrationUuid}
        showCancelModal={showCancelModal}
        handleHideCancelModal={handleHideCancelModal}
      />
      {currentRegistration && <RegisterCalendarView selectedDate={currentRegistration} />}
    </div>
  );
};
