import { CancelIcon, SkeletonContainer } from 'hocs/shared-styles';
import {
  ColleaguesCount,
  ContentWrapper,
  LoaderWrapper,
  OptionsWrapper,
  PageWrapper,
  SearchWrapper,
  StyledButton,
  StyledContact,
  UserOptions,
  Wrapper,
} from './styles';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Calendar as CalendarIcon } from 'assets/icons/calendar';
import { CalendarLoadingSkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { CalendarModal } from 'pages/calendar/calendar-modal/calendar-modal';
import { ColleaguesLoadingSkeleton } from './colleagues-loading-skeleton';
import { CurrentModalView } from './types';
import { DayPicker } from '@hqo/react-components-library/dist/molecules/day-picker';
import { EmptyState } from '@hqo/react-components-library/dist/molecules/empty-state';
import { Groups } from 'assets/icons/groups';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';
import { RegisterCalendarView } from 'components/register-calendar-view';
import { Search } from 'pages/calendar/close-colleagues/styles';
import { Users } from 'assets/icons/users';
import { currentUser } from 'store/user/selectors';
import { selectBrandTheme } from '../../store/theme/selectors';
import { useCalendar } from './use-calendar';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import { useInfiniteScroll } from '@hqo/react-components-library/dist/hooks/use-infinite-scroll';
import { useIntl } from 'react-intl';
import { useRegistrations } from './use-registrations';
import { useSdk } from 'hooks/use-sdk.hook';
import { useSelector } from 'react-redux';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { useTeammates } from './use-teammates';
import { selectBuilding } from 'store/building/selectors';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from './tracking/tracking';

export const Calendar: React.FC = () => {
  const {
    selectedDate,
    title,
    onSelectDay,
    showLeftArrow,
    locale,
    showRightArrow,
    onLeftArrowClick,
    onRightArrowClick,
    handleGetTeammateRegistrations,
    isInitalTeammatesFetch,
  } = useCalendar();
  const { dates, selectedDaysData, selectedDaysContacts, isInitalFetch, selectedDayUserRegistration, hasContacts } =
    useRegistrations(selectedDate);
  const {
    selectedDaysTeammates,
    isBeforeToday,
    hasMoreTeammates,
    isTeammatesLoading,
    selectedDaysTeammatesCount,
    currentTeammatesIsLoading,
  } = useTeammates(selectedDate);

  const intl = useIntl();
  const { currentModalView, handleShowModal } = useCalendarModal();
  const user = useSelector(currentUser);
  const theme = useSelector(selectBrandTheme);
  const { handleCloseApp } = useSdk();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const building = useSelector(selectBuilding);
  const { handleTracking } = useTracking();

  const { loaderRef } = useInfiniteScroll({
    callback: handleGetTeammateRegistrations,
    hasMore: hasMoreTeammates,
  });

  useEffect(() => {
    handleTracking(TrackingEvents.MainScreenImpression, 'impression');
  }, []);

  const emptyFunc = useCallback(() => null, []);
  const hasSelectedDayContacts = selectedDaysContacts.length > 0;
  const renderEmpty = useMemo(() => {
    const teammatesGoing = !hasSelectedDayContacts && selectedDaysTeammates?.length;
    const noOneGoing = !hasSelectedDayContacts && !selectedDaysTeammates?.length;
    if (!hasContacts) {
      return (
        <EmptyState
          dataTestId="empty-contacts"
          Icon={Groups}
          title={intl.formatMessage({ id: 'emptyCloseColleagues.title' })}
          description={[
            intl.formatMessage({ id: 'emptyCloseColleagues.description1' }),
            intl.formatMessage({ id: 'emptyCloseColleagues.description2' }),
          ]}
        >
          <StyledButton
            data-testid="add-contacts-button"
            text={intl.formatMessage({ id: 'addCloseColleagues.title' })}
            variant="primary"
            size="md"
            onClick={handleShowModal(
              {
                view: CurrentModalView.CloseColleagues,
              },
              TrackingEvents.AddToTeam,
            )}
          />
        </EmptyState>
      );
    }
    if (hasContacts && teammatesGoing) {
      return (
        <EmptyState
          dataTestId="contacts-not-going"
          Icon={Groups}
          title={intl.formatMessage({ id: 'closeColleaguesNotGoing.title' })}
          description={[
            intl.formatMessage({ id: 'closeColleaguesNotGoing.description1' }),
            intl.formatMessage({ id: 'closeColleaguesNotGoing.description2' }),
          ]}
        />
      );
    }
    if (hasContacts && noOneGoing) {
      return (
        <EmptyState
          dataTestId="no-one-going"
          Icon={Groups}
          title={
            !selectedDayUserRegistration
              ? intl.formatMessage({ id: 'noOneGoing.title' })
              : intl.formatMessage({ id: 'noOneGoing.noOneElsetitle' })
          }
          description={[
            intl.formatMessage({ id: 'noOneGoing.description1' }),
            intl.formatMessage({ id: 'noOneGoing.description2' }),
          ]}
        />
      );
    }
    return null;
  }, [hasContacts, selectedDayUserRegistration, hasSelectedDayContacts, selectedDaysTeammates]);

  if (isInitalFetch && isInitalTeammatesFetch) {
    return (
      <SkeletonContainer data-testid="initial-load">
        {isSmallViewportWidth && <CancelIcon onClick={handleCloseApp} data-testid="cancel-icon" />}
        <CalendarLoadingSkeleton />
      </SkeletonContainer>
    );
  }
  return (
    <PageWrapper>
      <OptionsWrapper>
        {isSmallViewportWidth ? <CancelIcon onClick={handleCloseApp} data-testid="cancel-icon" /> : <div />}
        <UserOptions>
          <CalendarIcon
            data-testid="calendar-icon"
            onClick={handleShowModal({
              regUserUuid: user.uuid,
              regFirstName: user.first_name,
              regLastName: user.last_name,
              view: CurrentModalView.RegistrationsList,
            })}
          />
          <Groups
            data-testid="groups-icon"
            onClick={handleShowModal({
              view: CurrentModalView.CloseColleagues,
            })}
          />
        </UserOptions>
      </OptionsWrapper>

      <SearchWrapper>
        <Search
          onClick={handleShowModal({
            view: CurrentModalView.Search,
          })}
          placeholder={intl.formatMessage({ id: 'search' })}
          value={null}
          setValue={emptyFunc}
          hasIcon
        />
      </SearchWrapper>
      <DayPicker
        dataTestId="day-picker"
        title={title}
        selectedDate={selectedDate}
        locale={locale}
        onSelectDay={onSelectDay}
        dates={dates}
        selectedDaysData={selectedDaysData}
        showLeftArrow={showLeftArrow}
        showRightArrow={showRightArrow}
        onLeftArrowClick={onLeftArrowClick}
        onRightArrowClick={onRightArrowClick}
      />
      {currentTeammatesIsLoading ? (
        <ColleaguesLoadingSkeleton ref={loaderRef} />
      ) : (
        <ContentWrapper size={hasSelectedDayContacts ? 'small' : 'large'}>
          {renderEmpty}
          {hasSelectedDayContacts ? (
            <>
              <ColleaguesCount data-testid={`colleague-count-${selectedDaysContacts.length}`}>
                {intl.formatMessage(
                  { id: 'colleaguesGoingInBuilding' },
                  { count: selectedDaysContacts.length, buildingName: building.name },
                )}
              </ColleaguesCount>
              {selectedDaysContacts.map((contact) => (
                <StyledContact
                  key={contact.contact_uuid}
                  size="lg"
                  dataTestId={`contact-${contact.contact_uuid}`}
                  value={`${contact.first_name} ${contact.last_name}`}
                  avatarIcon={<Users />}
                  onClick={handleShowModal({
                    regUserUuid: contact.contact_uuid,
                    regFirstName: contact.first_name,
                    regLastName: contact.last_name,
                    view: CurrentModalView.RegistrationsList,
                  })}
                />
              ))}
            </>
          ) : null}
          {selectedDaysTeammates?.length ? (
            <Wrapper>
              <ColleaguesCount data-testid={`teammate-count-${selectedDaysTeammatesCount}`}>
                {intl.formatMessage(
                  { id: 'teammatesGoingInBuilding' },
                  { count: selectedDaysTeammatesCount, buildingName: building.name },
                )}
              </ColleaguesCount>
              {selectedDaysTeammates?.map((teammate) => (
                <StyledContact
                  key={teammate?.teammate_uuid}
                  size="lg"
                  dataTestId={`teammate-${teammate?.teammate_uuid}`}
                  value={`${teammate?.first_name} ${teammate?.last_name}`}
                />
              ))}
              <LoaderWrapper showMore={hasMoreTeammates} data-testid="infinite-loader-wrapper" ref={loaderRef}>
                {isTeammatesLoading && <Loader data-testid="infinite-loader" color={theme.primary_color} />}
              </LoaderWrapper>
            </Wrapper>
          ) : null}
        </ContentWrapper>
      )}
      {isBeforeToday && <RegisterCalendarView selectedDate={selectedDate} />}
      {currentModalView && <CalendarModal data-testid="calendar-modal" />}
    </PageWrapper>
  );
};
