import { flatten } from 'utils';
import messagesInCanadianFrench from '../../lang/fr-CA.json';
import messagesInDutch from '../../lang/nl-NL.json';
import messagesInEnglish from '../../lang/en.json';
import messagesInFrench from '../../lang/fr-FR.json';
import messagesInGerman from '../../lang/de-DE.json';
import messagesInSpanish from '../../lang/es-ES.json';
import messagesInItalian from '../../lang/it-IT.json';
import messagesInNorwegian from '../../lang/nb-NO.json';
import messagesInPolish from '../../lang/pl.json';
import messagesInPortuguese from '../../lang/pt-PT.json';
import { useMemo } from 'react';

export const DEFAULT_LOCALE = 'en';

const messagesMap = new Map<string, Record<string, unknown>>([
  ['en', messagesInEnglish.mfeWhoIsIn],
  ['es-es', messagesInSpanish.mfeWhoIsIn],
  ['fr-ca', messagesInCanadianFrench.mfeWhoIsIn],
  ['de-de', messagesInGerman.mfeWhoIsIn],
  ['nl-nl', messagesInDutch.mfeWhoIsIn],
  ['fr-fr', messagesInFrench.mfeWhoIsIn],
  ['it-it', messagesInItalian.mfeWhoIsIn],
  ['nb-no', messagesInNorwegian.mfeWhoIsIn],
  ['pl', messagesInPolish.mfeWhoIsIn],
  ['pt-pt', messagesInPortuguese.mfeWhoIsIn],
]);

export const getAvailableLocale = (userLocale: string): [string, string] => {
  if (messagesMap.has(userLocale)) {
    return [userLocale, userLocale];
  }

  const [lang] = userLocale.split('-');

  if (messagesMap.has(lang)) {
    return [lang, userLocale];
  }

  return [DEFAULT_LOCALE, DEFAULT_LOCALE];
};

interface LocaleData {
  messages: Record<string, string>;
  locale: string;
}

export const useLocaleData = (userLocale: string): LocaleData =>
  useMemo(() => {
    const [messagesKey, locale] = getAvailableLocale(userLocale);

    return {
      locale,
      messages: flatten(messagesMap.get(messagesKey)) as Record<string, string>,
    };
  }, [userLocale]);
