import * as contactsActions from 'store/contacts/actions';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUserUuid } from 'store/user/selectors';
import {
  selectEmployeesAreLoading,
  selectShouldFetchEmployees,
  selectEmployees,
  selectEmployeesPaginationLimit,
  selectEmployeesPaginationOffset,
  selectEmployeesPaginationTotal,
} from 'store/contacts/selectors';
import { UserContact } from 'store/contacts/types';
import { usePrevious } from 'hooks/use-previous.hook';
import { useDebounce } from 'hooks/use-debounce.hook';
import { TrackingEvents } from 'pages/calendar/tracking';
import { useTracking } from 'hooks/use-tracking';

interface Props {
  searchValue: string;
}

export interface UseEmployeesResponse {
  addContact: (contact: UserContact) => () => void;
  getEmployees: (isScrolling?: boolean, resetOffset?: boolean) => void;
  infiniteScrollCallback: () => void;
  isInitialLoad: boolean;
  isResetting: boolean;
  employees: UserContact[];
  employeesAreLoading: boolean;
  total: number;
}

export const useEmployees = ({ searchValue }: Props): UseEmployeesResponse => {
  const dispatch: (payload: unknown) => void = useDispatch();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const currentUserUuid: string = useSelector(selectCurrentUserUuid);
  const shouldFetchEmployees: boolean = useSelector(selectShouldFetchEmployees);
  const employeesAreLoading: boolean = useSelector(selectEmployeesAreLoading);
  const employees: UserContact[] = useSelector(selectEmployees);
  const limit: number = useSelector(selectEmployeesPaginationLimit);
  const offset: number = useSelector(selectEmployeesPaginationOffset);
  const total: number = useSelector(selectEmployeesPaginationTotal);
  const debouncedSearchValue: string = useDebounce(searchValue);
  const previousDebouncedSearchValue: string = usePrevious(debouncedSearchValue);
  const { handleTracking } = useTracking();

  const getEmployees = useCallback(
    (isScrolling = false, resetOffset = false) => {
      dispatch(
        contactsActions.getEmployees.request({
          user_uuid: currentUserUuid,
          offset: offset === null || resetOffset ? 0 : offset + limit,
          limit,
          sort: 'first_name',
          isScrolling,
          full_name: debouncedSearchValue,
        }),
      );
      if (resetOffset) {
        setIsResetting(true);
      }
    },
    [limit, offset, debouncedSearchValue],
  );

  const addContact = useCallback(
    (contact: UserContact) => () => {
      dispatch(contactsActions.createContacts.request({ user_uuid: currentUserUuid, contact_uuid: contact.uuid }));
      handleTracking(TrackingEvents.AddCloseColleagues);
      document?.getElementById('close-colleagues-subtitle')?.scrollIntoView();
    },
    [],
  );

  const infiniteScrollCallback = useCallback((): void => getEmployees(true), [getEmployees]);

  useEffect(() => {
    if (shouldFetchEmployees) {
      getEmployees();
    }
  }, [shouldFetchEmployees]);

  useEffect(() => {
    if (debouncedSearchValue !== null && previousDebouncedSearchValue !== debouncedSearchValue) {
      getEmployees(false, true);
    }
  }, [previousDebouncedSearchValue, debouncedSearchValue]);

  useEffect(() => {
    if (isInitialLoad && !employeesAreLoading) {
      setIsInitialLoad(false);
    }
  }, [employeesAreLoading]);

  useEffect(() => {
    if (!employeesAreLoading && isResetting) {
      setIsResetting(false);
    }
  }, [isResetting, employeesAreLoading]);

  return {
    addContact,
    getEmployees,
    infiniteScrollCallback,
    isInitialLoad,
    isResetting,
    employees,
    employeesAreLoading,
    total,
  };
};
