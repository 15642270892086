import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  height: 60px;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledSubtitle = styled.p`
  padding-top: 0px;
  margin-bottom: 24px;
  margin-top: 0px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  font-size: 12px;
`;
