import React, { useCallback } from 'react';
import {
  CheckContainer,
  Container,
  ContentContainer,
  StyledButton,
  StyledConfirm,
  StyledSubtitle,
  StyledTitle,
  ButtonContainer,
} from './registration-success.styles';
import { useIntl } from 'react-intl';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import moment from 'moment';
import { StyledLoader } from 'pages/calendar/registrations-list/styles';
import { useRegisterCalendarView } from 'components/register-calendar-view/use-register-calendar-view';
import { useDispatch } from 'react-redux';
import { goBack } from 'store/router/actions';

export const RegistrationSuccess = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { selectedDate } = useCalendarModal();
  const { isLoading, theme } = useRegisterCalendarView(selectedDate);

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, []);

  if (isLoading) {
    return <StyledLoader data-testid="loading-indicator" color={theme.primary_color} />;
  }
  return (
    <>
      <Container data-testid="register-success">
        <CheckContainer>
          <StyledConfirm size="lg" data-testid="register-success-check" />
        </CheckContainer>
        <ContentContainer>
          <StyledTitle data-testid="register-success-title">
            {intl.formatMessage({ id: 'registrationSuccess.registered' })}
          </StyledTitle>
          <StyledSubtitle data-testid="register-success-subtitle">
            {intl.formatMessage({ id: 'registrationSuccess.registeredSubtitle' })}
          </StyledSubtitle>
          <StyledSubtitle data-testid="register-success-date">
            {intl.formatMessage(
              { id: 'registrationSuccess.registeredDate' },
              {
                date: moment(selectedDate).format('dddd, ll'),
              },
            )}
          </StyledSubtitle>
        </ContentContainer>
      </Container>
      <ButtonContainer>
        <StyledButton
          data-testid="register-success-done-button"
          variant="primary"
          text={intl.formatMessage({ id: 'registrationSuccess.done' })}
          onClick={handleGoBack}
        />
      </ButtonContainer>
    </>
  );
};
