import { StyledMobileModal, CloseButton } from './modal.styles';

import React from 'react';
import { useIntl } from 'react-intl';

interface MobileModalProps {
  children: React.ReactNode;
  onClose?: VoidFunction;
  hideMobileCloseButton?: boolean;
}

export const MobileModal = ({
  children,
  onClose: handleClose,
  hideMobileCloseButton,
}: MobileModalProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const closeButtonText = formatMessage({ id: 'buttons.back' });

  return (
    <StyledMobileModal data-testid="mobile-modal-window" data-cy="modal-window">
      {children}
      {!hideMobileCloseButton && (
        <CloseButton
          onClick={handleClose}
          variant="link"
          text={closeButtonText}
          data-testid="close-modal"
          data-cy="close-modal"
        />
      )}
    </StyledMobileModal>
  );
};
