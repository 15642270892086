import { setUser as setSentryUser } from '@sentry/react';
import { usePendoAnalytics } from 'hooks/use-pendo-analytics.hook';
import { useEffect } from 'react';
import { initializePendo } from 'utils/pendo';

export const useAnalytics = (): void => {
  const analytics = usePendoAnalytics();

  useEffect(() => {
    if (window.pendo && analytics) {
      initializePendo(window.pendo, analytics);
    }

    setSentryUser(analytics);
  }, [analytics]);
};
