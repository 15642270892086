import { useTracking } from 'hooks/use-tracking';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import { TrackingEvents } from 'pages/calendar/tracking';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRegistration } from 'store/registrations/actions';
import { currentUser } from 'store/user/selectors';

export const useCancelRegistration = (registrationUuid: string, handleHideCancelModal: () => void) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const { userUuid } = useCalendarModal();
  const isCurrentUser = user.uuid === userUuid;
  const { handleTracking } = useTracking();
  const handleCancelRegistration = useCallback(() => {
    dispatch(
      deleteRegistration.request({
        user_uuid: user.uuid,
        registration_uuid: registrationUuid,
        isCurrentUser: true,
      }),
    );
    handleTracking(TrackingEvents.CancelRegistrationClicks);
    handleHideCancelModal();
  }, [registrationUuid, isCurrentUser]);
  return { handleCancelRegistration };
};
