import { Route, Routes } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { AppRoot } from 'components/app-root';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { Private } from 'components/router/private';
import React from 'react';
import { Calendar } from 'pages/calendar';
import { CALENDAR_PATH } from '../../shared/consts/paths';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path="/" element={<AppRoot />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route
        path={CALENDAR_PATH}
        element={
          <Private>
            <Calendar />
          </Private>
        }
      />
      <Route element={<DefaultRedirect />} />
    </Routes>
  </AppContentWrapper>
);
