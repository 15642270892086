import * as actions from './actions';

import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { getErrorCode } from 'store/utils/get-error-code.util';

export const getAltBuildingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getAltBuildings.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => apiClient(state)
    .getAltBuildings(payload)
    .pipe(
      map((response) => actions.getAltBuildings.success(response.response)),
      catchError((error) => of(actions.getAltBuildings.failure({ error, errorCode: getErrorCode(error) }))),
    )),
);
