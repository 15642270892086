import { useSelector } from 'react-redux';

import { UserContact } from 'store/contacts/types';
import { UseTeammatesResponse, useTeammates } from './useTeammates.hook';
import { IntlShape, useIntl } from 'react-intl';
import { BuildingTheme } from 'store/theme/types';
import { selectBrandTheme } from 'store/theme/selectors';
import { useInfiniteScroll } from '@hqo/react-components-library/dist/hooks/use-infinite-scroll';
import React, { useMemo, useEffect } from 'react';
import {
  AvatarContainer,
  LoaderWrapper,
  StyledCalendarIcon,
  StyledEmptyState,
  TeammatesWrapper,
  GroupsIcon,
  StyledAddPersonIcon,
} from 'pages/calendar/close-colleagues/styles';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';
import { DisplayItem } from '@hqo/react-components-library/dist/molecules/display-item';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import Minus from 'assets/icons/minus';
import Search from 'assets/icons/search';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from 'pages/calendar/tracking';
import { CurrentModalView } from 'pages/calendar/types';

interface Props {
  searchValue: string;
}

export const Teammates = ({ searchValue }: Props) => {
  const {
    addContact,
    deleteContact,
    isInitialLoad,
    isResetting,
    teammates,
    teammatesAreLoading,
    total,
    goToUserRegistrations,
    debouncedSearchValue,
    infiniteScrollCallback,
  }: UseTeammatesResponse = useTeammates({ searchValue });
  const intl: IntlShape = useIntl();
  const theme: BuildingTheme = useSelector(selectBrandTheme);
  const { loaderRef } = useInfiniteScroll({
    callback: infiniteScrollCallback,
    hasMore: total > teammates.length,
  });
  const { handleShowModal } = useCalendarModal();
  const shouldShowInitialLoader: boolean =
    (teammatesAreLoading && isInitialLoad) || isResetting || isInitialLoad || debouncedSearchValue !== searchValue;
  const shouldShowInfinteLoader: boolean = teammatesAreLoading && !isInitialLoad && !isResetting;
  const shouldShowEmptyState: boolean = !teammatesAreLoading && teammates.length === 0 && debouncedSearchValue !== null;
  const { handleTracking } = useTracking();

  useEffect(() => {
    handleTracking(TrackingEvents.SearchScreenImpression, 'impression');
  }, []);

  const renderTeammates = useMemo(() => {
    if (shouldShowInitialLoader) {
      return (
        <LoaderWrapper data-testid="loader-wrapper">
          <Loader data-testid="loader-initial" color={theme.primary_color} />
        </LoaderWrapper>
      );
    }
    if (shouldShowEmptyState) {
      return (
        <StyledEmptyState
          dataTestId="empty-contacts"
          Icon={Search}
          title={intl.formatMessage({ id: 'emptyCloseColleagues.teammatesTitle' })}
          description={[
            intl.formatMessage({ id: 'emptyCloseColleagues.teammatesDescription1' }),
            intl.formatMessage({ id: 'emptyCloseColleagues.teammatesDescription2' }),
          ]}
        />
      );
    }
    return teammates.map((teammate: UserContact) => (
      <DisplayItem
        key={teammate.uuid}
        avatarIcon={
          teammate.isContact && (
            <AvatarContainer>
              <GroupsIcon />
            </AvatarContainer>
          )
        }
        actions={[
          {
            icon: (
              <StyledCalendarIcon
                data-testid="view-registrations"
                size="lg"
                onClick={goToUserRegistrations({
                  userUuid: teammate.uuid,
                  firstName: teammate.first_name,
                  lastName: teammate.last_name,
                })}
              />
            ),
          },
          {
            icon: teammate.isContact ? (
              <Minus data-testid="remove-contact" size="lg" onClick={deleteContact(teammate)} />
            ) : (
              <StyledAddPersonIcon data-testid="add-contact" size="lg" onClick={addContact(teammate)} />
            ),
          },
        ]}
        dataTestId="teammateRow"
        showShadow
        value={`${teammate.first_name} ${teammate.last_name}`}
        onClick={handleShowModal({
          regUserUuid: teammate.uuid,
          regFirstName: teammate.first_name,
          regLastName: teammate.last_name,
          regShowBackButton: true,
          view: CurrentModalView.RegistrationsList,
        })}
      />
    ));
  }, [teammates, addContact, handleShowModal, theme.primary_color, shouldShowInitialLoader, shouldShowEmptyState]);
  return (
    <TeammatesWrapper>
      {renderTeammates}
      <LoaderWrapper data-testid="infinite-loader-wrapper" ref={loaderRef}>
        {shouldShowInfinteLoader && <Loader data-testid="infinite-loader-initial" color={theme.primary_color} />}
      </LoaderWrapper>
    </TeammatesWrapper>
  );
};
