import { createSelector } from 'reselect';
import { RootState } from '../reducer';
import { TeammateRegistrationState, TeammateRegistrationView, UserRegistrationsState } from './types';

interface SelectTeammatesRegistrations {
  userUuid: string;
  view: TeammateRegistrationView;
}

export const registrationsStateSelector = (state: RootState): UserRegistrationsState => state.registrations;

export const selectUserRegistrations = createSelector(
  [registrationsStateSelector, (_, userUuid) => userUuid],
  (registrations, userUuid) => registrations[userUuid]?.registrations ?? [],
);

export const selectUserTempRegistrations = createSelector(
  [registrationsStateSelector, (_, userUuid) => userUuid],
  (registrations, userUuid) => registrations[userUuid]?.tempRegistrations ?? [],
);

export const selectTeammateRegistrations = createSelector(
  [
    registrationsStateSelector,
    (_, userUuid, view) => ({
      userUuid,
      view,
    }),
  ],
  (registrations, { userUuid, view }: SelectTeammatesRegistrations) =>
    registrations[userUuid]?.[view] as TeammateRegistrationState,
);

export const selectUserRegistrationsStatus = createSelector(
  [registrationsStateSelector, (_, userUuid) => userUuid],
  (registrations, userUuid) => registrations[userUuid]?.getRegistrations?.status,
);

export const selectCreateRegistrationsStatus = createSelector(
  [registrationsStateSelector, (_, userUuid) => userUuid],
  (registrations, userUuid) => registrations[userUuid]?.createRegistrations?.status,
);

export const selectUpdateRegistrationsStatus = createSelector(
  [registrationsStateSelector, (_, userUuid) => userUuid],
  (registrations, userUuid) => registrations[userUuid]?.updateRegistration?.status,
);

export const selectTeammmateRegistrationStatus = createSelector(
  [
    registrationsStateSelector,
    (_, userUuid, view) => ({
      userUuid,
      view,
    }),
  ],
  (registrations, { userUuid, view }: SelectTeammatesRegistrations) =>
    registrations[userUuid]?.[view]?.getTeammateRegistrations?.status,
);
