import * as userActions from '../user/actions';

import { ConfigState } from './types';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getDefaultApiUrl } from 'store/config/get-default-url.util';
import { saveExternalConfig } from './actions';
import { initialState } from './initialState';
import { Config } from './enums';

const apiUrlReducer = createReducer(initialState.apiUrl)
  .handleAction(userActions.externalLogin.request, (_state, action) => {
    const { apiUrl } = action.payload;

    if (apiUrl) {
      localStorage.setItem(Config.API_URL_LS_KEY, apiUrl);
    } else {
      localStorage.removeItem(Config.API_URL_LS_KEY);
    }

    return apiUrl ?? getDefaultApiUrl();
  })
  .handleAction(userActions.externalLogin.failure, () => {
    localStorage.removeItem(Config.API_URL_LS_KEY);

    return getDefaultApiUrl();
  });

const getExternalConfigSaveReducer = <K extends keyof ConfigState>(key: K, lsKey: string) => (
  state: ConfigState[K],
  action: ReturnType<typeof saveExternalConfig>,
): ConfigState[K] => {
  const value: string | undefined | null = action.payload[key];

  if (value) {
    localStorage.setItem(lsKey, value);
  }

  return value ?? state;
};

const appBrandReducer = createReducer(initialState.appBrand).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('appBrand', Config.APP_BRAND_LS_KEY),
);

const buildingUuidReducer = createReducer(initialState.buildingUuid).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('buildingUuid', Config.BUILDING_UUID_LS_KEY),
);

const localeReducer = createReducer(initialState.locale).handleAction(
  saveExternalConfig,
  getExternalConfigSaveReducer('locale', Config.LOCALE_LS_KEY),
);

export default combineReducers({
  apiUrl: apiUrlReducer,
  appBrand: appBrandReducer,
  buildingUuid: buildingUuidReducer,
  locale: localeReducer,
});
