import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { replace, goBack, push } from 'store/router/actions';
import { CalendarQueryParams } from 'pages/calendar/types';
import { CurrentModalView } from '../types';
import { useCallback } from 'react';
import { currentUser } from 'store/user/selectors';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from 'pages/calendar/tracking';

interface HandleShowRegistrations {
  regUserUuid?: string;
  regFirstName?: string;
  regLastName?: string;
  regShowBackButton?: boolean;
  view: CurrentModalView;
}

export const useCalendarModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userUuid = queryParams.get(CalendarQueryParams.UserUuid);
  const firstName = queryParams.get(CalendarQueryParams.FirstName);
  const lastName = queryParams.get(CalendarQueryParams.LastName);
  const selectedDate = queryParams.get(CalendarQueryParams.SelectedDate);
  const backButton = queryParams.get(CalendarQueryParams.ShowBackButton);
  const currentModalView = queryParams.get(CalendarQueryParams.CurrentModalView);

  const showBackButton = backButton === 'true';

  const user = useSelector(currentUser);
  const isCurrentUser = user.uuid === userUuid;
  const { handleTracking } = useTracking();

  const handleShowModal = useCallback(
    (
        { regUserUuid, regFirstName, regLastName, regShowBackButton, view }: HandleShowRegistrations,
        trackingEvent?: TrackingEvents,
      ) =>
      () => {
        const params = qs.stringify(
          {
            [CalendarQueryParams.UserUuid]: regUserUuid,
            [CalendarQueryParams.FirstName]: regFirstName,
            [CalendarQueryParams.LastName]: regLastName,
            [CalendarQueryParams.ShowBackButton]: regShowBackButton,
            [CalendarQueryParams.CurrentModalView]: view,
          },
          {
            skipNulls: true,
            arrayFormat: 'comma',
            addQueryPrefix: true,
            encode: false,
          },
        );
        if (trackingEvent) {
          handleTracking(trackingEvent);
        }
        if (currentModalView === 'closeColleagues' || currentModalView === 'search') {
          dispatch(push(`${location.pathname}${params}`));
        }
        dispatch(replace(`${location.pathname}${params}`));
      },
    [],
  );

  const handleCloseRegistrations = useCallback(() => {
    dispatch(replace(location.pathname));
  }, [location.pathname]);

  const handleGoBack = useCallback(() => {
    dispatch(goBack());
  }, []);

  return {
    handleShowModal,
    handleCloseRegistrations,
    handleGoBack,
    userUuid,
    firstName,
    lastName,
    selectedDate,
    currentModalView,
    isCurrentUser,
    showBackButton,
  };
};
