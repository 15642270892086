import styled from 'styled-components';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';
import { EmptyState } from '@hqo/react-components-library/dist/molecules/empty-state';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const StyledEmptyState = styled(EmptyState)`
  padding-top: 34px;
`;

export const StyledLoader = styled(Loader)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  padding: 5px 16px;
`;
