import {
  DeleteRegistrationSuccessPayload,
  UserRegistration,
  UserRegistrationsState,
  CreateRegistrationsSuccessPayload,
  UpdateRegistrationsSuccessPayload,
} from './types';
import * as actions from './actions';
import { ActionType, createReducer } from 'typesafe-actions';
import { ACTION_STATUSES } from 'shared/consts';
import uniqBy from 'lodash.uniqby';

export const INITIAL_STATE: UserRegistrationsState = {};

const getUserRegistrationRequestHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.getUserRegistrations.request>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    getRegistrations: {
      error: null,
      status: ACTION_STATUSES.PENDING,
    },
  },
});

const getUserRegistrationSuccessHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.getUserRegistrations.success>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    registrations: payload.isCurrentUser ? state[payload.user_uuid]?.registrations : payload.registrations,
    tempRegistrations: payload.isCurrentUser ? payload.registrations : null,
    getRegistrations: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  },
});

const getUserRegistrationFailureHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.getUserRegistrations.failure>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    getRegistrations: {
      error: payload.error,
      status: ACTION_STATUSES.REJECTED,
    },
  },
});

const getTeammateRegistrationsRequestHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.getTeammateRegistrations.request>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    [payload.view]: {
      ...state[payload.user_uuid]?.[payload.view],
      getTeammateRegistrations: {
        error: null,
        status: ACTION_STATUSES.PENDING,
      },
    },
  },
});

const getTeammateRegistrationsSuccessHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.getTeammateRegistrations.success>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    [payload.view]: {
      ...state[payload.user_uuid]?.[payload.view],
      teammates: state[payload.user_uuid]?.[payload.view]?.teammates
        ? uniqBy([...state[payload.user_uuid][payload.view].teammates, ...payload.teammates], 'teammate_uuid')
        : payload.teammates,
      limit: payload.limit,
      offset: payload.offset,
      total: payload.total,
      getTeammateRegistrations: {
        error: null,
        status: ACTION_STATUSES.FULFILLED,
      },
    },
  },
});

const getTeammateRegistrationsFailureHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.getTeammateRegistrations.failure>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    [payload.view]: {
      ...state[payload.user_uuid]?.[payload.view],
      getTeammateRegistrations: {
        error: payload.error,
        status: ACTION_STATUSES.REJECTED,
      },
    },
  },
});

const resetTeammateRegistrationHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.resetTeammateRegistrations>,
): UserRegistrationsState => ({
  ...state,
  [payload.userUuid]: {
    ...state[payload.userUuid],
    [payload.view]: null,
  },
});

const createRegistrationRequestHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.createRegistrations.request>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    createRegistrations: {
      error: null,
      status: ACTION_STATUSES.PENDING,
    },
  },
});

const setCreateTempRegistrationsState = (state: UserRegistrationsState, payload: CreateRegistrationsSuccessPayload) => {
  if (payload.isCurrentUser) {
    if (state[payload.user_uuid]?.tempRegistrations) {
      return [...state[payload.user_uuid].tempRegistrations, ...payload.data.registrations];
    }
    return [...payload.data.registrations];
  }
  return null;
};

const handleCreateRegistration = (state: UserRegistrationsState, payload: CreateRegistrationsSuccessPayload) => {
  const registrationsCopy = [...state[payload.user_uuid].registrations];
  const index = registrationsCopy.findIndex(
    (item: UserRegistration) => item.date === payload.data.registrations[0].date,
  );

  if (index >= 0) {
    registrationsCopy[index] = { ...registrationsCopy[index], ...payload.data.registrations[0] };
  } else {
    return [...state[payload.user_uuid].registrations, ...payload.data.registrations];
  }
  return registrationsCopy;
};

const createRegistrationSuccessHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.createRegistrations.success>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    registrations: state[payload.user_uuid]
      ? handleCreateRegistration(state, payload)
      : [...payload.data.registrations],
    tempRegistrations: setCreateTempRegistrationsState(state, payload),
    createRegistrations: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  },
});

const createRegistrationFailureHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.createRegistrations.failure>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    createRegistrations: {
      error: payload.error,
      status: ACTION_STATUSES.REJECTED,
    },
  },
});

const deleteRegistrationRequestHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.deleteRegistration.request>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    deleteRegistration: {
      error: null,
      status: ACTION_STATUSES.PENDING,
    },
  },
});

const handleDeleteRegistration = (state: UserRegistrationsState, payload: DeleteRegistrationSuccessPayload) => {
  const registrationsCopy = [...state[payload.user_uuid].registrations];
  const index = registrationsCopy.findIndex((item: UserRegistration) => item.uuid === payload.registration_uuid);
  if (index >= 0) {
    registrationsCopy[index] = {
      date: registrationsCopy[index].date,
      contacts: registrationsCopy[index].contacts,
      teammate_count: registrationsCopy[index].teammate_count,
    } as UserRegistration;
  }

  return registrationsCopy;
};

const handleDeleteTempRegistrationsState = (
  state: UserRegistrationsState,
  payload: DeleteRegistrationSuccessPayload,
) => {
  if (payload.isCurrentUser && state[payload.user_uuid]?.tempRegistrations) {
    const registrationsCopy = [...state[payload.user_uuid].tempRegistrations];
    const index = registrationsCopy.findIndex((item: UserRegistration) => item.uuid === payload.registration_uuid);
    if (index >= 0) {
      registrationsCopy[index] = {
        date: registrationsCopy[index].date,
        contacts: registrationsCopy[index].contacts,
      } as UserRegistration;
    }

    return registrationsCopy;
  }
  return null;
};

const deleteRegistrationSuccessHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.deleteRegistration.success>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    registrations: state[payload.user_uuid]
      ? handleDeleteRegistration(state, payload)
      : [...state[payload.user_uuid].registrations],
    tempRegistrations: handleDeleteTempRegistrationsState(state, payload),
    deleteRegistration: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  },
});

const deleteRegistrationFailureHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.deleteRegistration.failure>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    deleteRegistration: {
      error: payload.error,
      status: ACTION_STATUSES.REJECTED,
    },
  },
});

const updateRegistrationRequestHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.updateRegistration.request>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    updateRegistration: {
      error: null,
      status: ACTION_STATUSES.PENDING,
    },
  },
});

const handleUpdateRegistration = (state: UserRegistrationsState, payload: UpdateRegistrationsSuccessPayload) => {
  const registrationsCopy = [...state[payload.user_uuid].registrations];
  const index = registrationsCopy.findIndex((item: UserRegistration) => item.uuid === payload.uuid);

  if (index >= 0) {
    registrationsCopy[index].date = payload.date;
    registrationsCopy[index].private = payload.private;
  }

  return registrationsCopy;
};

const handleUpdateTempRegistrations = (state: UserRegistrationsState, payload: UpdateRegistrationsSuccessPayload) => {
  if (payload.isCurrentUser) {
    const newState = { ...state };
    const index = state[payload.user_uuid].tempRegistrations?.findIndex(
      (item: UserRegistration) => item.uuid === payload.uuid,
    );

    if (index >= 0) {
      newState[payload.user_uuid].tempRegistrations[index].date = payload.date;
      newState[payload.user_uuid].tempRegistrations[index].private = payload.private;
    }

    return newState[payload.user_uuid].tempRegistrations;
  }
  return null;
};

const updateRegistrationSuccessHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.updateRegistration.success>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    registrations: state[payload.user_uuid]
      ? handleUpdateRegistration(state, payload)
      : [...state[payload.user_uuid].registrations],
    tempRegistrations: handleUpdateTempRegistrations(state, payload),
    updateRegistration: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  },
});

const updateRegistrationFailureHandler = (
  state: UserRegistrationsState,
  { payload }: ActionType<typeof actions.updateRegistration.failure>,
): UserRegistrationsState => ({
  ...state,
  [payload.user_uuid]: {
    ...state[payload.user_uuid],
    updateRegistration: {
      error: payload.error,
      status: ACTION_STATUSES.REJECTED,
    },
  },
});

const UserRegistrationsReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getUserRegistrations.request, getUserRegistrationRequestHandler)
  .handleAction(actions.getUserRegistrations.success, getUserRegistrationSuccessHandler)
  .handleAction(actions.getUserRegistrations.failure, getUserRegistrationFailureHandler)
  .handleAction(actions.getTeammateRegistrations.request, getTeammateRegistrationsRequestHandler)
  .handleAction(actions.getTeammateRegistrations.success, getTeammateRegistrationsSuccessHandler)
  .handleAction(actions.getTeammateRegistrations.failure, getTeammateRegistrationsFailureHandler)
  .handleAction(actions.resetTeammateRegistrations, resetTeammateRegistrationHandler)
  .handleAction(actions.createRegistrations.request, createRegistrationRequestHandler)
  .handleAction(actions.createRegistrations.success, createRegistrationSuccessHandler)
  .handleAction(actions.createRegistrations.failure, createRegistrationFailureHandler)
  .handleAction(actions.deleteRegistration.request, deleteRegistrationRequestHandler)
  .handleAction(actions.deleteRegistration.success, deleteRegistrationSuccessHandler)
  .handleAction(actions.deleteRegistration.failure, deleteRegistrationFailureHandler)
  .handleAction(actions.updateRegistration.request, updateRegistrationRequestHandler)
  .handleAction(actions.updateRegistration.success, updateRegistrationSuccessHandler)
  .handleAction(actions.updateRegistration.failure, updateRegistrationFailureHandler);

export default UserRegistrationsReducer;
