export enum CalendarQueryParams {
  UserUuid = 'userUuid',
  FirstName = 'firstName',
  LastName = 'lastName',
  SelectedDate = 'selectedDate',
  CurrentModalView = 'currentModalView',
  ShowBackButton = 'showBackButton',
}

export enum CurrentModalView {
  RegistrationsList = 'registrationsList',
  IndividualRegistrations = 'individualRegistrations',
  CloseColleagues = 'closeColleagues',
  RegistrationSuccess = 'registrationSuccess',
  Search = 'search',
}
