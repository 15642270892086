import {
  CreateContactsPayload,
  CreateContactsSuccessPayload,
  DeleteContactPayload,
  DeleteContactSuccessPayload,
  GetContactsPayload,
  GetContactsSuccessPayload,
  GetEmployeesPayload,
  GetEmployeesSuccessPayload,
  GetTeammatesPayload,
  GetTeammatesSuccessPayload,
} from './types';
import { FailureActionPayload } from 'store/errors/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const createContacts = createAsyncAction(
  'CREATE_CONTACTS_REQUEST',
  'CREATE_CONTACTS_SUCCESS',
  'CREATE_CONTACTS_FAILURE',
)<CreateContactsPayload, CreateContactsSuccessPayload, FailureActionPayload>();

export const deleteContact = createAsyncAction(
  'DELETE_CONTACT_REQUEST',
  'DELETE_CONTACT_SUCCESS',
  'DELETE_CONTACT_FAILURE',
)<DeleteContactPayload, DeleteContactSuccessPayload, FailureActionPayload>();

export const getEmployees = createAsyncAction(
  'GET_EMPLOYEES_REQUEST',
  'GET_EMPLOYEES_SUCCESS',
  'GET_EMPLOYEES_FAILURE',
)<GetEmployeesPayload, GetEmployeesSuccessPayload, FailureActionPayload>();

export const getTeammates = createAsyncAction(
  'GET_TEAMMATES_REQUEST',
  'GET_TEAMMATES_SUCCESS',
  'GET_TEAMMATES_FAILURE',
)<GetTeammatesPayload, GetTeammatesSuccessPayload, FailureActionPayload>();

export const getContacts = createAsyncAction('GET_CONTACTS_REQUEST', 'GET_CONTACTS_SUCCESS', 'GET_CONTACTS_FAILURE')<
  GetContactsPayload,
  GetContactsSuccessPayload,
  FailureActionPayload
>();

export const resetContacts = createAction('RESET_CONTACTS')<null>();
