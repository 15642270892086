import { Button, Switch } from '@hqo/react-components-library';
import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;

  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;

  border-top: ${({ theme }) => `1px solid ${theme.colors.greys.adminGreyLight}`};
  background-color: ${({ theme }) => theme.colors.universal.white};
`;

export const RegistrationContainer = styled.div`
  margin: 17px 24px;

  font-size: 16px;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.greys.darkGrey7};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div<{ isSmallViewportWidth: boolean }>`
  display: flex;
  justify-content: center;
  margin: ${({ isSmallViewportWidth }) => isSmallViewportWidth && '20px 8px 12px'};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  padding: 13px 16px;
`;

export const StyledSwitch = styled(Switch)`
  &:checked:before {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
  &:checked {
    background-color: ${({ theme }) => theme.colors.secondary}61;
  }
`;
