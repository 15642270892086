import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import {
  StyledButton,
  StyledDialogContent,
  CancelIconWrapper,
  StyledCancelIcon,
  CancelTitle,
  CancelSubtitle,
  StyledConfirmDialog,
} from 'components/cancel-registrations-dialog/styles';
import { useCancelRegistration } from 'components/cancel-registrations-dialog/use-cancel-registration';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  registrationUuid: string;
  showCancelModal: boolean;
  handleHideCancelModal: () => void;
}

export const CancelRegistrationsDialog: React.FC<Props> = ({
  registrationUuid,
  showCancelModal,
  handleHideCancelModal,
}: Props) => {
  const { handleCancelRegistration } = useCancelRegistration(registrationUuid, handleHideCancelModal);
  const intl = useIntl();
  const isSmallViewportWidth = useIsSmallViewportWidth();

  return (
    <StyledConfirmDialog
      data-testid="cancel-registrations-dialog"
      open={showCancelModal}
      onClose={handleHideCancelModal}
      showBottomOfScreen={isSmallViewportWidth}
    >
      <StyledDialogContent>
        <CancelIconWrapper>
          <StyledCancelIcon />
        </CancelIconWrapper>
        <CancelTitle>{intl.formatMessage({ id: 'cancelRegistration.title' })}</CancelTitle>
        <CancelSubtitle>{intl.formatMessage({ id: 'cancelRegistration.subtitle' })}</CancelSubtitle>
        <StyledButton
          data-testid="cancel-registration-dialog-button"
          text={intl.formatMessage({ id: 'cancelRegistration.agree' })}
          variant="danger"
          size="tenant"
          onClick={handleCancelRegistration}
          isSmallViewportWidth={isSmallViewportWidth}
        />
      </StyledDialogContent>
    </StyledConfirmDialog>
  );
};
