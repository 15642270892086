import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DialogContent } from '@hqo/react-components-library/dist/confirm-dialog';
import { ConfirmAction } from '@hqo/react-components-library/dist/confirm-dialog/confirm-dialog-actions/confirm-action';
import { DialogTitle } from '@hqo/react-components-library/dist/confirm-dialog/confirm-dialog-title';
import Cancel from 'assets/icons/cancel';
import styled from 'styled-components';
import { ConfirmDialog } from '@hqo/react-components-library';

export const StyledConfirmDialog = styled(ConfirmDialog)<{ showBottomOfScreen: boolean }>`
  .MuiDialog-scrollPaper {
    align-items: ${({ showBottomOfScreen }) => (showBottomOfScreen ? 'end' : 'center')};
  }

  .MuiDialog-paper {
    margin: 0px;
    ${({ showBottomOfScreen }) =>
      showBottomOfScreen
        ? `border-radius: 0px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;`
        : 'border-radius: 8px;'}

    width: 98%;
  }
`;

export const StyledConfirmAction = styled(ConfirmAction)`
  flex-direction: row-reverse;
  &.root {
    margin: 0;
    width: auto;
  }
  & button:nth-of-type(2) {
    border: none;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  &.root {
    font-family: ${({ theme }) => theme.fonts.join()};
    margin: 0;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  &.root {
    font-family: ${({ theme }) => theme.fonts.join()};
    margin: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CancelIconWrapper = styled.div`
  background: ${({ theme }) => theme.colorsRgba.pink_1};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
`;

export const StyledCancelIcon = styled(Cancel)`
  color: ${({ theme }) => theme.colors.admin.pink};
`;

export const CancelTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
  margin: 16px 0px 24px;
`;

export const CancelSubtitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const handleIphone = (isSmallViewportWidth: boolean): string => {
  const isIphone = window?.navigator?.userAgent.match(/iPhone/i);
  if (isIphone && isSmallViewportWidth) {
    return '32px 0px env(safe-area-inset-bottom); margin: 32px 0px constant(safe-area-inset-bottom);';
  }
  return '32px 0px 20px;';
};

export const StyledButton = styled(Button)<{isSmallViewportWidth: boolean}>`
  width: 100%;
  min-width: unset;

  margin: ${({ isSmallViewportWidth }) => handleIphone(isSmallViewportWidth)};
`;
