import * as altBuildingsActions from 'store/alt-buildings/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as buildingActions from 'store/building/actions';
import * as uiMetadataActions from 'store/ui-metadata/actions';
import * as registrationActions from 'store/registrations/actions';

import { concatMap, filter, map } from 'rxjs/operators';
import { showErrorNotification, showGenericErrorNotification } from './actions';

import { ERROR_PATH } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { replace } from 'store/router/actions';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    // Add failure actions to trigger error modal here and in the test file
    filter(isActionOf([])),
    map((action) =>
      showErrorNotification({
        action: action.type,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
      }),
    ),
  );

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    // Add failure actions to trigger error page redirects here and in the test file
    filter(
      isActionOf([
        themeActions.getBuildingTheme.failure,
        userActions.externalLogin.failure,
        userActions.getCurrentUser.failure,
        altBuildingsActions.getAltBuildings.failure,
        buildingActions.getBuilding.failure,
        uiMetadataActions.fetchUiMetadata.failure,
        registrationActions.getUserRegistrations.failure,
      ]),
    ),
    concatMap((action) =>
      of(
        showGenericErrorNotification({
          action: action.type,
          error: action.payload.error,
          errorCode: action.payload.errorCode,
        }),
        replace(ERROR_PATH),
      ),
    ),
  );
