import * as actions from './actions';

import { Epic } from 'redux-observable';
import { filter, of } from 'rxjs';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { withLatestFrom, switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { selectBuilding } from '../building/selectors';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { currentUser } from '../user/selectors';

export const getRegistrationsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getUserRegistrations.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      const company_uuid = currentUser(state)?.company_uuid;
      return apiClient(state)
        .getRegistrations({ ...payload, building_uuid, company_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.getUserRegistrations.success({
              registrations: xhrPayload.response.data.registrations,
              user_uuid: payload.user_uuid,
              isCurrentUser: payload.isCurrentUser,
            }),
          ),
          catchError((error) =>
            of(
              actions.getUserRegistrations.failure({
                user_uuid: payload.user_uuid,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const getTeammateRegistrations: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getTeammateRegistrations.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      const company_uuid = currentUser(state)?.company_uuid;
      return apiClient(state)
        .getTeammateRegistrations({ ...payload, building_uuid, company_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.getTeammateRegistrations.success({
              teammates: xhrPayload.response.data.teammates,
              user_uuid: payload.user_uuid,
              limit: payload.limit,
              offset: xhrPayload.response.data.offset,
              total: xhrPayload.response.data.total,
              view: payload.view,
            }),
          ),
          catchError((error) =>
            of(
              actions.getTeammateRegistrations.failure({
                user_uuid: payload.user_uuid,
                error,
                errorCode: getErrorCode(error),
                view: payload.view,
              }),
            ),
          ),
        );
    }),
  );

export const createRegistrationsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.createRegistrations.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      const company_uuid = currentUser(state)?.company_uuid;
      return apiClient(state)
        .createRegistrations({ ...payload, building_uuid, company_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.createRegistrations.success({
              user_uuid: payload.user_uuid,
              data: { registrations: xhrPayload.response.data.registrations },
              isCurrentUser: payload.isCurrentUser,
            }),
          ),
          catchError((error) =>
            of(
              actions.createRegistrations.failure({
                user_uuid: payload.user_uuid,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const updateRegistrationEpic: Epic = (action$, state$, { apiClient }) =>
  action$.pipe(
    filter(isActionOf(actions.updateRegistration.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      const company_uuid = currentUser(state)?.company_uuid;
      return apiClient(state)
        .updateRegistration({ ...payload, building_uuid, company_uuid })
        .pipe(
          map(() =>
            actions.updateRegistration.success({
              user_uuid: payload.user_uuid,
              uuid: payload.uuid,
              date: payload.date,
              private: payload.private,
              isCurrentUser: payload.isCurrentUser,
            }),
          ),
          catchError((error) =>
            of(
              actions.updateRegistration.failure({
                user_uuid: payload.user_uuid,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const deleteRegistrationEpic: Epic = (action$, state$, { apiClient }) =>
  action$.pipe(
    filter(isActionOf(actions.deleteRegistration.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      const company_uuid = currentUser(state)?.company_uuid;
      return apiClient(state)
        .deleteRegistration({ ...payload, building_uuid, company_uuid })
        .pipe(
          map(() =>
            actions.deleteRegistration.success({
              user_uuid: payload.user_uuid,
              registration_uuid: payload.registration_uuid,
              isCurrentUser: payload.isCurrentUser,
            }),
          ),
          catchError((error) =>
            of(
              actions.deleteRegistration.failure({
                user_uuid: payload.user_uuid,
                registration_uuid: payload.registration_uuid,
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );
