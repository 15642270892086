import * as contactsActions from 'store/contacts/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectContactsAreLoading,
  selectContactsHaveLoaded,
  selectShouldFetchContacts,
  selectUserContacts,
} from 'store/contacts/selectors';
import { UserContact } from 'store/contacts/types';
import { selectCurrentUserUuid } from 'store/user/selectors';
import { useDebounce } from 'hooks/use-debounce.hook';
import { usePrevious } from 'hooks/use-previous.hook';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from 'pages/calendar/tracking';

interface Props {
  searchValue: string;
}

export interface UseContactsResponse {
  contacts: UserContact[];
  contactsAreLoading: boolean;
  contactsHaveLoaded: boolean;
  deleteContact: (contact: UserContact) => () => void;
}

export const useContacts = ({ searchValue }: Props): UseContactsResponse => {
  const dispatch: (payload: unknown) => void = useDispatch();
  const currentUserUuid: string = useSelector(selectCurrentUserUuid);
  const shouldFetchContacts: boolean = useSelector(selectShouldFetchContacts);
  const contactsAreLoading: boolean = useSelector(selectContactsAreLoading);
  const contactsHaveLoaded: boolean = useSelector(selectContactsHaveLoaded);
  const contacts: UserContact[] = useSelector(selectUserContacts);
  const debouncedSearchValue: string = useDebounce(searchValue);
  const previousDebouncedSearchValue: string = usePrevious(debouncedSearchValue);
  const { handleTracking } = useTracking();

  const getContacts = useCallback((search: string = null) => {
    dispatch(
      contactsActions.getContacts.request({
        full_name: search,
      }),
    );
  }, []);

  const deleteContact = useCallback(
    (contact: UserContact) => () => {
      dispatch(contactsActions.deleteContact.request({ user_uuid: currentUserUuid, contact_uuid: contact.uuid }));
      handleTracking(TrackingEvents.RemoveColleagues);
    },
    [],
  );

  useEffect(() => {
    if (shouldFetchContacts) {
      getContacts();
    }
  }, [shouldFetchContacts]);

  useEffect(() => {
    if (debouncedSearchValue !== null && previousDebouncedSearchValue !== debouncedSearchValue) {
      getContacts(debouncedSearchValue);
    }
  }, [debouncedSearchValue, previousDebouncedSearchValue]);

  return {
    contacts,
    contactsAreLoading,
    contactsHaveLoaded,
    deleteContact,
  };
};
