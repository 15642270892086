import { Button } from '@hqo/react-components-library/dist/atoms/button';
import CancelThin from '../../assets/icons/cancel-thin';
import { DisplayItem } from '@hqo/react-components-library/dist/molecules/display-item';
import { People } from 'assets/icons/people';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 24px 16px;
`;

export const StyledPeopleIcon = styled(People)`
  color: ${({ theme }) => theme.colors.universal.white};
  path {
    stroke: ${({ theme }) => theme.colors.greys.darkGrey7};
  }
`;

export const ColleaguesCount = styled.p`
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  font-family: ${({ theme }) => theme.fonts[0]};
  font-size: 12px;
  line-height: 22px;
`;

export const StyledContact = styled(DisplayItem)`
  margin: 0;
  margin-bottom: 16px;
`;

export const ContentWrapper = styled.div<{ size: string }>`
  margin-top: ${({ size }) => (size === 'small' ? '24px' : '32px')};
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    color: ${({ theme }) => theme.colors.greys.adminBlack};
  }
`;

export const UserOptions = styled.div`
  display: flex;
  gap: 20px;
`;

export const CancelIcon = styled(CancelThin)`
  max-width: 14px;
  max-height: 14px;
`;

export const LoaderWrapper = styled.div<{ showMore: boolean }>`
  display: flex;
  height: ${({ showMore }) => (showMore ? '160px' : '108px')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DataDisplayContainer = styled.div`
  position: fixed;
  overflow: hidden;
`;

export const SkeletonContainer = styled.div`
  padding-top: 16px;
`;

export const SearchWrapper = styled.div`
  // changing styles from here instead of RCL so as not to break any backwards compatibility for other apps
  rect {
    height: 0;
    width: 0;
  }
`;

export const StyledButton = styled(Button)`
  min-width: unset;
  margin: 10px 0px 16px;
`;
