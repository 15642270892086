/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const Cancel: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
  >
    <path
      d="M11.4911 7.75L15.788 3.45312C16.3466 2.9375 16.3466 2.07812 15.788 1.5625L14.8427 0.617188C14.327 0.0585938 13.4677 0.0585938 12.952 0.617188L8.65517 4.91406L4.31533 0.617188C3.7997 0.0585938 2.94033 0.0585938 2.4247 0.617188L1.47939 1.5625C0.920797 2.07812 0.920797 2.9375 1.47939 3.45312L5.77627 7.75L1.47939 12.0898C0.920797 12.6055 0.920797 13.4648 1.47939 13.9805L2.4247 14.9258C2.94033 15.4844 3.7997 15.4844 4.31533 14.9258L8.65517 10.6289L12.952 14.9258C13.4677 15.4844 14.327 15.4844 14.8427 14.9258L15.788 13.9805C16.3466 13.4648 16.3466 12.6055 15.788 12.0898L11.4911 7.75Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Cancel;
