import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducer';
import {
  selectUserRegistrations,
  selectUserRegistrationsStatus,
  selectUserTempRegistrations,
} from 'store/registrations/selectors';
import { getUserRegistrations } from 'store/registrations/actions';
import moment from 'moment-timezone';
import { useEffect, useCallback, useState } from 'react';
import { currentUser } from 'store/user/selectors';
import { useCalendarModal } from '../calendar-modal/use-calendar-modal';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { ACTION_STATUSES } from 'shared/consts';
import { TimeframeEnum, UserRegistration } from 'store/registrations/types';

moment.tz.setDefault('Etc/UTC');

export const useRegistrationsList = () => {
  const user = useSelector(currentUser);
  const { userUuid } = useCalendarModal();
  const isCurrentUser = user.uuid === userUuid;

  const userRegistrations = useSelector((state: RootState) => selectUserRegistrations(state, userUuid));
  const userTempRegistrations = useSelector((state: RootState) => selectUserTempRegistrations(state, userUuid));
  const userRegistrationStatus = useSelector((state: RootState) => selectUserRegistrationsStatus(state, userUuid));

  const registrationsToUse = isCurrentUser ? userTempRegistrations : userRegistrations;

  const isLoading = userRegistrationStatus === ACTION_STATUSES.PENDING;

  const { value: showCancelModal, setTrue: setShowCancelModal, setFalse: setHideCancelModal } = useBooleanState(false);
  const [cancellationRegistrationUuid, setCancellationRegistrationUuid] = useState(null);
  const dispatch = useDispatch();

  const [currentRegistration, setCurrentRegistration] = useState(null);

  useEffect(() => {
    dispatch(
      getUserRegistrations.request({
        user_uuid: userUuid,
        start_date: moment().format('YYYY-MM-DD'),
        timeframe: TimeframeEnum.ALL,
        isCurrentUser,
      }),
    );
  }, [userUuid]);

  const handleShowCancelModal = useCallback(
    (registrationUuid: string) => () => {
      setCancellationRegistrationUuid(registrationUuid);
      setShowCancelModal();
    },
    [],
  );

  const handleHideCancelModal = useCallback(() => {
    setCancellationRegistrationUuid(null);
    setHideCancelModal();
  }, []);

  const handleSelectedRegistration = useCallback(
    (registration: UserRegistration) => () => {
      setCurrentRegistration(registration.date);
    },
    [],
  );

  return {
    isCurrentUser,
    userRegistrations: registrationsToUse,
    cancellationRegistrationUuid,
    showCancelModal,
    isLoading,
    userUuid,
    handleHideCancelModal,
    handleShowCancelModal,
    handleSelectedRegistration,
    currentRegistration,
    user,
  };
};
