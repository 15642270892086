/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const CancelThin: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CancelThin;
