import { initializeWebTracking, track } from '@hqo/web-tracking';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectApiUrl } from 'store/config/selectors';

export const useTracking = () => {
  const apiUrl = useSelector(selectApiUrl);

  const handleInitializeTracking = useCallback((buildingUuid: string) => {
    initializeWebTracking(localStorage.authToken, buildingUuid, 'mfe-who-is-in', navigator.userAgent, apiUrl || '');
  }, []);

  const handleTracking = useCallback((eventName: string, type: 'action' | 'impression' = 'action') => {
    track(eventName, { type });
  }, []);

  return {
    handleInitializeTracking,
    handleTracking,
  };
};
