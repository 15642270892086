import { getUserRegistrations, resetTeammateRegistrations } from 'store/registrations/actions';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACTION_STATUSES } from '../../shared/consts/action-statuses';
import { TeammateRegistrationView } from '../../store/registrations/types';
import { TimeframeEnum } from 'store/registrations/types';
import { currentUser } from 'store/user/selectors';
import { getTeammateRegistrations } from '../../store/registrations/actions';
import moment from 'moment-timezone';
import { selectLocale } from '../../store/config/selectors';
import { useIntl } from 'react-intl';
import { usePrevious } from '../../hooks/use-previous.hook';
import { useRegistrations } from 'pages/calendar/use-registrations';
import { useTeammates } from './use-teammates';
import { selectCreateContactsStatus, selectDeleteContactsStatus } from 'store/contacts/selectors';
import { TrackingEvents } from 'pages/calendar/tracking';
import { useTracking } from 'hooks/use-tracking';

moment.tz.setDefault('Etc/UTC');

const COMPLETED_STATUSES = [ACTION_STATUSES.FULFILLED, ACTION_STATUSES.REJECTED];

export const useCalendar = () => {
  const {
    selectedDaysTeammates,
    currentUserTeammatesRegistrations,
    previousUserTeammatesRegistrationStatus,
    currentUserTeammatesRegistrationsStatus,
  } = useTeammates();
  const { handleTracking } = useTracking();
  const { previousUserRegistrationStatus, currentUserRegistrationsStatus, getStartOfWeek } = useRegistrations();
  const user = useSelector(currentUser);
  const locale = useSelector(selectLocale);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
  const onSelectDay = useCallback((updatedDate: string) => {
    handleTracking(TrackingEvents.DayClick);
    setSelectedDate(moment(updatedDate).format('YYYY-MM-DD'));
  }, []);
  const previousSelectedDate = usePrevious(selectedDate);
  const createContactStatus = useSelector(selectCreateContactsStatus);
  const previousCreateContactStatus = usePrevious(createContactStatus);
  const deleteContactStatus = useSelector(selectDeleteContactsStatus);
  const previousDeleteContactStatus = usePrevious(deleteContactStatus);

  const dispatch = useDispatch();
  const isInitialRender = useRef(true);
  const intl = useIntl();
  const [isInitalTeammatesFetch, setIsInitialTeammatesFetch] = useState(true);

  const handleGetTeammateRegistrations = useCallback(
    (reset?: boolean) => {
      dispatch(
        getTeammateRegistrations.request({
          user_uuid: user.uuid,
          date: selectedDate,
          offset: reset ? 0 : selectedDaysTeammates?.length,
          limit: 10,
          view: TeammateRegistrationView.Calendar,
        }),
      );
    },
    [currentUserTeammatesRegistrations?.teammates?.length, selectedDate, selectedDaysTeammates, user.uuid],
  );

  useEffect(() => {
    const currentStartOfWeek = getStartOfWeek(selectedDate);
    const previousStartOfWeek = getStartOfWeek(previousSelectedDate);
    const weekChanged = currentStartOfWeek !== previousStartOfWeek;
    const dateChanged = selectedDate !== previousSelectedDate;

    const createContactSucceeded =
      previousCreateContactStatus === ACTION_STATUSES.PENDING && createContactStatus === ACTION_STATUSES.FULFILLED;
    const deleteContactSucceeded =
      previousDeleteContactStatus === ACTION_STATUSES.PENDING && deleteContactStatus === ACTION_STATUSES.FULFILLED;
    const contactsChanged = createContactSucceeded || deleteContactSucceeded;

    const resetTeammates = dateChanged || contactsChanged || isInitialRender.current;
    if (resetTeammates) {
      dispatch(resetTeammateRegistrations({ userUuid: user.uuid, view: TeammateRegistrationView.Calendar }));
      handleGetTeammateRegistrations(true);
    }

    const resetRegistrations = weekChanged || isInitialRender.current || contactsChanged;
    if (resetRegistrations) {
      dispatch(
        getUserRegistrations.request({
          user_uuid: user.uuid,
          start_date: getStartOfWeek(selectedDate),
          timeframe: TimeframeEnum.WEEK,
        }),
      );
    }
    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [
    user.uuid,
    selectedDate,
    previousSelectedDate,
    previousCreateContactStatus,
    createContactStatus,
    previousDeleteContactStatus,
    deleteContactStatus,
    selectedDate,
  ]);

  // This is used for the initial loader when we are making the initial teammates registrations call
  useEffect(() => {
    const previousTeammatesIsLoading = previousUserTeammatesRegistrationStatus === ACTION_STATUSES.PENDING;
    const currentTeammatesIsComplete = COMPLETED_STATUSES.includes(currentUserTeammatesRegistrationsStatus);
    if (previousTeammatesIsLoading && currentTeammatesIsComplete && isInitalTeammatesFetch) {
      setIsInitialTeammatesFetch(false);
    }
  }, [previousUserRegistrationStatus, currentUserTeammatesRegistrationsStatus, currentUserRegistrationsStatus]);

  const title = useMemo(() => {
    const selectedDateIsToday = moment(selectedDate).isSame(moment().startOf('day'), 'day');
    const weekdayTitle = selectedDateIsToday
      ? intl.formatMessage({ id: 'calendar.today' })
      : moment(selectedDate).format('dddd');

    return `${weekdayTitle}, ${moment(selectedDate).locale(locale).format('LL')}`;
  }, [selectedDate]);

  const showLeftArrow = useMemo(() => {
    const currentDay = moment();
    const selectedDay = moment(selectedDate);
    const currentWeek = currentDay.isoWeek();
    const selectedWeek = selectedDay.isoWeek();
    const currentYear = currentDay.year();
    const selectedYear = selectedDay.year();
    const isGreaterThanCurrentWeek = selectedWeek > currentWeek;
    const isGreaterThanCurrentYear = selectedYear > currentYear;
    if (isGreaterThanCurrentWeek || isGreaterThanCurrentYear) {
      return true;
    }

    return false;
  }, [selectedDate]);

  const onLeftArrowClick = useCallback(() => {
    setSelectedDate((prevDate) => {
      const newDate = moment(prevDate).subtract(1, 'weeks').isoWeekday(1).format('YYYY-MM-DD');
      handleTracking(TrackingEvents.PreviousWeekClick);

      return newDate;
    });
  }, []);

  const onRightArrowClick = useCallback(() => {
    setSelectedDate((prevDate) => {
      const newDate = moment(prevDate).add(1, 'weeks').isoWeekday(1).format('YYYY-MM-DD');
      handleTracking(TrackingEvents.NextWeekClick);

      return newDate;
    });
  }, []);

  return {
    selectedDate,
    title,
    onSelectDay,
    showLeftArrow,
    locale,
    showRightArrow: true,
    onLeftArrowClick,
    onRightArrowClick,
    handleGetTeammateRegistrations,
    isInitalTeammatesFetch,
  };
};
