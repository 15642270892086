import * as actions from './actions';

import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { filter, of } from 'rxjs';

import { Epic } from 'redux-observable';
import { RootAction } from '../actions';
import { RootDependencies } from '../dependencies';
import { RootState } from '../reducer';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { isActionOf } from 'typesafe-actions';
import { selectBuilding } from '../building/selectors';
import { selectCurrentUserUuid } from 'store/user/selectors';
import { GetEmployeesPayload, GetTeammatesPayload } from './types';
import { selectEmployeesPaginationLimit, selectEmployeesPaginationOffset } from './selectors';

export const createContactsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.createContacts.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      return apiClient(state)
        .createContacts({ ...payload, building_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.createContacts.success({
              data: { contacts: xhrPayload.response.data.contacts },
            }),
          ),
          catchError((error) =>
            of(
              actions.createContacts.failure({
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const deleteContactEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteContact.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      return apiClient(state)
        .deleteContact({ ...payload, building_uuid })
        .pipe(
          map(() =>
            actions.deleteContact.success({
              contact_uuid: payload.contact_uuid,
            }),
          ),
          catchError((error) =>
            of(
              actions.deleteContact.failure({
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const getEmployeesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf([actions.getEmployees.request])),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const building_uuid: string = selectBuilding(state)?.uuid;
      const user_uuid: string = selectCurrentUserUuid(state);
      const limit: number = selectEmployeesPaginationLimit(state);
      const offset: number = selectEmployeesPaginationOffset(state);

      let newPayload = { ...payload } as GetEmployeesPayload;
      if (newPayload.user_uuid === undefined) {
        newPayload = {
          ...newPayload,
          user_uuid,
        };
      }
      if (newPayload.limit === undefined) {
        newPayload = {
          ...newPayload,
          limit,
        };
      }
      if (newPayload.offset === undefined) {
        newPayload = {
          ...newPayload,
          offset,
        };
      }
      return apiClient(state)
        .getEmployees({ ...newPayload, building_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.getEmployees.success({
              paginatedEmployees: {
                employees: xhrPayload.response.data.employees,
                limit: xhrPayload.response.data.limit,
                offset: xhrPayload.response.data.offset,
                isScrolling: newPayload.isScrolling,
                total: xhrPayload.response.data.total,
              },
            }),
          ),
          catchError((error) =>
            of(
              actions.getEmployees.failure({
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const getTeammatesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf([actions.getTeammates.request])),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const building_uuid: string = selectBuilding(state)?.uuid;
      const user_uuid: string = selectCurrentUserUuid(state);
      const limit: number = selectEmployeesPaginationLimit(state);
      const offset: number = selectEmployeesPaginationOffset(state);

      let newPayload = { ...payload } as GetTeammatesPayload;
      if (newPayload.user_uuid === undefined) {
        newPayload = {
          ...newPayload,
          user_uuid,
        };
      }
      if (newPayload.limit === undefined) {
        newPayload = {
          ...newPayload,
          limit,
        };
      }
      if (newPayload.offset === undefined) {
        newPayload = {
          ...newPayload,
          offset,
        };
      }
      return apiClient(state)
        .getTeammates({ ...newPayload, building_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.getTeammates.success({
              paginatedTeammates: {
                teammates: xhrPayload.response.data.teammates,
                limit: xhrPayload.response.data.limit,
                offset: xhrPayload.response.data.offset,
                isScrolling: newPayload.isScrolling,
                total: xhrPayload.response.data.total,
              },
            }),
          ),
          catchError((error) =>
            of(
              actions.getTeammates.failure({
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );

export const getContactsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getContacts.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const building_uuid = selectBuilding(state)?.uuid;
      const user_uuid = selectCurrentUserUuid(state);
      return apiClient(state)
        .getContacts({ ...payload, user_uuid, building_uuid })
        .pipe(
          map((xhrPayload) =>
            actions.getContacts.success({
              contacts: xhrPayload.response.data.contacts,
            }),
          ),
          catchError((error) =>
            of(
              actions.getContacts.failure({
                error,
                errorCode: getErrorCode(error),
              }),
            ),
          ),
        );
    }),
  );
