import React, { forwardRef } from 'react';
import { ColleaguesHeader, DataDisplaySkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { DataDisplayContainer, SkeletonContainer } from 'pages/calendar/styles';

export const ColleaguesLoadingSkeleton = forwardRef<HTMLDivElement>((_props, ref) => (
  <SkeletonContainer data-testid="infinite-loader-initial" ref={ref}>
    <ColleaguesHeader />
    <DataDisplaySkeleton />
    <ColleaguesHeader />
    <DataDisplayContainer>
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
      <DataDisplaySkeleton />
    </DataDisplayContainer>
  </SkeletonContainer>
));

ColleaguesLoadingSkeleton.displayName = 'LoadingSkeleton';
