/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const People: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect width="20" height="20" rx="10" fill="currentColor" />
    <path
      d="M11.5 14.5V13.5C11.5 12.9696 11.2893 12.4609 10.9142 12.0858C10.5391 11.7107 10.0304 11.5 9.5 11.5H6.5C5.96957 11.5 5.46086 11.7107 5.08579 12.0858C4.71071 12.4609 4.5 12.9696 4.5 13.5V14.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9.5C9.10457 9.5 10 8.60457 10 7.5C10 6.39543 9.10457 5.5 8 5.5C6.89543 5.5 6 6.39543 6 7.5C6 8.60457 6.89543 9.5 8 9.5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 5.56494C12.4302 5.67509 12.8115 5.92529 13.0838 6.2761C13.3561 6.6269 13.5039 7.05836 13.5039 7.50244C13.5039 7.94653 13.3561 8.37798 13.0838 8.72879C12.8115 9.07959 12.4302 9.32979 12 9.43994"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 14.5V13.5C15.5 12.9696 15.2893 12.4609 14.9142 12.0858C14.5391 11.7107 14.0304 11.5 13.5 11.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default People;
