import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PendoAnalytics } from 'utils/pendo';
import { detect } from 'detect-browser';
import { User } from 'store/user/types';
import { configSelector } from 'store/config/selectors';
import { DEFAULT_APP_BRAND } from 'shared/consts';
import { selectBuilding } from 'store/building/selectors';
import { Building } from 'store/building/types';
import { currentUser } from 'store/user/selectors';

const useBrowser = () => useMemo(() => detect(), []);

const getAccountAnalytics = (building: Building): PendoAnalytics['account'] => ({
  id: `${building.uuid}${process.env.REACT_APP_PENDO_ID_SUFFIX ?? ''}`,
  current_building_uuid: building.uuid,
  current_building_name: building.name,
  current_portfolio_id: building.portfolio_uuid,
});

const getVisitorAnalytics = (
  accountId: string,
  building: Building,
  user: User,
  browser: ReturnType<typeof detect>,
  appBrand: string | null,
): PendoAnalytics['visitor'] => ({
  id: `${user.uuid}${process.env.REACT_APP_PENDO_ID_SUFFIX ?? ''}`,
  account: accountId,
  test_user: !!user.test,
  current_building_name: building.name,
  current_portfolio_id: building.portfolio_uuid,
  tenant_company_id: user.company_uuid,
  app_brand: appBrand ?? DEFAULT_APP_BRAND,
  current_user_roles: user.roles.map(({ name }) => name),
  os_name: browser?.name ?? '',
  os_version: browser?.version ?? '',
});

export const usePendoAnalytics = (): PendoAnalytics | null => {
  const building = useSelector(selectBuilding);
  const { appBrand } = useSelector(configSelector);
  const user = useSelector(currentUser);
  const browser = useBrowser();

  return useMemo(() => {
    if (!building || !user) {
      return null;
    }

    const account = getAccountAnalytics(building);
    const visitor = getVisitorAnalytics(account.id, building, user, browser, appBrand);

    return {
      visitor,
      account,
    };
  }, [building, user, browser, appBrand]);
};
