import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';

export const useSdk = () => {
  const miniappSdk = useMiniappSdk();

  const handleCloseApp = () => {
    miniappSdk.header.showHeader();
    // @ts-expect-error: SDK is initialized in index.html
    sdk.navigation.close();
  };
  return {
    handleCloseApp,
  };
};
