import {
  selectTeammateRegistrations,
  selectTeammmateRegistrationStatus,
  selectUserRegistrationsStatus,
} from '../../store/registrations/selectors';
import { useEffect, useState } from 'react';

import { ACTION_STATUSES } from '../../shared/consts/action-statuses';
import { RootState } from '../../store/reducer';
import { TeammateRegistrationView } from '../../store/registrations/types';
import { currentUser } from '../../store/user/selectors';
import moment from 'moment-timezone';
import { usePrevious } from '../../hooks/use-previous.hook';
import { useSelector } from 'react-redux';

export const useTeammates = (selectedDate?: string) => {
  const user = useSelector(currentUser);
  const currentUserTeammatesRegistrations = useSelector((state: RootState) =>
    selectTeammateRegistrations(state, user?.uuid, TeammateRegistrationView.Calendar),
  );
  const currentUserTeammatesRegistrationsStatus = useSelector((state: RootState) =>
    selectTeammmateRegistrationStatus(state, user?.uuid, TeammateRegistrationView.Calendar),
  );
  const [isTeammatesInitialLoad, setIsTeammatesInitialLoad] = useState(true);
  const isTeammatesLoading =
    currentUserTeammatesRegistrationsStatus === ACTION_STATUSES.PENDING || !currentUserTeammatesRegistrationsStatus;

  const currentUserRegistrationsStatus = useSelector((state: RootState) =>
    selectUserRegistrationsStatus(state, user?.uuid),
  );
  const previousUserTeammatesRegistrationStatus = usePrevious(currentUserTeammatesRegistrationsStatus);
  const selectedDaysTeammates = currentUserTeammatesRegistrations?.teammates;
  const selectedDaysTeammatesCount = currentUserTeammatesRegistrations?.total;

  const hasMoreTeammates =
  currentUserTeammatesRegistrations?.teammates?.length < currentUserTeammatesRegistrations?.total;

  const isBeforeToday = selectedDate >= moment().startOf('day').format('YYYY-MM-DD');
  const teammatesOrRegistrationsIsLoading =
    currentUserTeammatesRegistrationsStatus === ACTION_STATUSES.PENDING ||
    currentUserRegistrationsStatus === ACTION_STATUSES.PENDING;

  const currentTeammatesIsLoading = teammatesOrRegistrationsIsLoading && !hasMoreTeammates;

  useEffect(() => {
    if (isTeammatesInitialLoad && !isTeammatesLoading) {
      setIsTeammatesInitialLoad(false);
    }
  }, [isTeammatesLoading]);

  return {
    selectedDaysTeammates,
    isBeforeToday,
    hasMoreTeammates,
    isTeammatesLoading,
    selectedDaysTeammatesCount,
    currentUserTeammatesRegistrations,
    previousUserTeammatesRegistrationStatus,
    currentUserTeammatesRegistrationsStatus,
    currentTeammatesIsLoading,
  };
};
