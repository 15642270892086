import React, { useEffect, useRef, useState } from 'react';
import { useIntl, IntlShape } from 'react-intl';
import { Contacts } from './contacts';
import { Search, SubTitle } from './styles';
import { Employees } from './employees';
import { Teammates } from 'pages/calendar/close-colleagues/teammates';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import { CurrentModalView } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { resetContacts } from 'store/contacts/actions';
import { selectBuilding } from 'store/building/selectors';

export const CloseColleagues: React.FC = (): JSX.Element => {
  const intl: IntlShape = useIntl();
  const [searchValue, setSearchValue] = useState<string>(null);
  const { currentModalView } = useCalendarModal();
  const searchRef = useRef(null as HTMLDivElement);
  const dispatch = useDispatch();
  const building = useSelector(selectBuilding);

  const isSearchView = currentModalView === CurrentModalView.Search;
  const showGlobalSearchView = searchValue || isSearchView;

  useEffect(() => {
    if (isSearchView) {
      // Added set timeout to account for time it takes for modal to open
      setTimeout(() => {
        searchRef.current.querySelector('input')?.focus();
      }, 290);
    }
    // when opening from search bar, need the input to automatically focus
  }, [isSearchView]);

  useEffect(
    () => () => {
      dispatch(resetContacts());
    },
    [],
  );

  return (
    <>
      <div ref={searchRef}>
        <Search
          id="user-search"
          value={searchValue}
          setValue={setSearchValue}
          hasClearButton={!!searchValue}
          placeholder={intl.formatMessage({ id: 'closeColleagues.searchPlaceholder' })}
          hasIcon
        />
      </div>
      {searchValue || showGlobalSearchView ? (
        <Teammates searchValue={searchValue} />
      ) : (
        <>
          <SubTitle id="close-colleagues-subtitle">
            {intl.formatMessage({ id: 'closeColleagues.closeColleaguesSubtitle' })}
          </SubTitle>
          <Contacts searchValue={searchValue} />
          <SubTitle>{intl.formatMessage({ id: 'closeColleagues.allColleaguesSubtitleInBuilding' },
            { buildingName: building.name })}
          </SubTitle>
          <Employees searchValue={searchValue} />
        </>
      )}
    </>
  );
};
