import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Root } from 'components/root';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'moment/min/locales';

const root = createRoot(document.getElementById('root'));

Sentry.init({
  dsn: 'https://454ae3d3d2e6455b9ff7d3de94be5ad4@o379338.ingest.sentry.io/6706325',
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
