/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const Calendar: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
  >
    <path
      d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4C0 3.45 0.195667 2.97933 0.587 2.588C0.979 2.196 1.45 2 2 2H3V0H5V2H13V0H15V2H16C16.55 2 17.021 2.196 17.413 2.588C17.8043 2.97933 18 3.45 18 4V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H2ZM2 18H16V8H2V18Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Calendar;
