import { AppThemeProvider, DefaultThemeWithFonts } from '@hqo/react-components-library/dist/molecules/theme';

import { ACTION_STATUSES } from 'shared/consts';
import { AppContentWrapperStyles } from 'components/app-content-wrapper/app-content-wrapper.styles';
import { CustomFonts } from 'components/custom-fonts';
import React, { useEffect } from 'react';
import { selectBuildingStatus } from 'store/building/selectors';
import { useBuilding } from 'hooks/use-building.hook';
import { useBuildingTheme } from 'hooks/use-building-theme.hook';
import { useSelector } from 'react-redux';
import { useViewParams } from 'hooks/use-view-params.hook';
import { ErrorModal } from 'components/error-modal';
import { CalendarLoadingSkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { SkeletonContainer, CancelIcon } from 'hocs/shared-styles';
import { useSdk } from 'hooks/use-sdk.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { useTracking } from 'hooks/use-tracking';

interface AppContentWrapperProps {
  children: React.ReactNode;
}

export const AppContentWrapper = ({ children }: AppContentWrapperProps): JSX.Element => {
  const { handleCloseApp } = useSdk();
  const params = useViewParams();
  const buildingStatus = useSelector(selectBuildingStatus);
  const theme = useBuildingTheme();
  const building = useBuilding();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const { handleInitializeTracking } = useTracking();

  const buildingStatusFinished =
    buildingStatus === ACTION_STATUSES.FULFILLED || buildingStatus === ACTION_STATUSES.REJECTED;

  const hasBuilding = building || params.buildingUuid;

  useEffect(() => {
    if (building?.uuid) {
      handleInitializeTracking(building.uuid);
    }
  }, [building?.uuid]);

  if (theme && (buildingStatusFinished || !hasBuilding)) {
    return (
      <>
        <AppContentWrapperStyles customThemeFonts={theme.fonts} />
        <CustomFonts />
        <AppThemeProvider theme={theme as DefaultThemeWithFonts}>
          {children}
          <ErrorModal />
        </AppThemeProvider>
      </>
    );
  }
  return (
    <SkeletonContainer data-testid="loading-container">
      {isSmallViewportWidth && <CancelIcon onClick={handleCloseApp} data-testid="cancel-icon" />}
      <CalendarLoadingSkeleton />
    </SkeletonContainer>
  );
};
