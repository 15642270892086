import CancelThin from 'assets/icons/cancel-thin';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: { isLoading: boolean }) => props.isLoading && 'center'};
  height: 100vh;
`;

export const SkeletonContainer = styled.div`
  padding: 16px;
`;

export const CancelIcon = styled(CancelThin)`
  max-width: 14px;
  max-height: 14px;
  color: ${({ theme }) => theme?.colors?.greys?.adminBlack};
`;
