import { useDispatch, useSelector } from 'react-redux';

import { Building } from 'store/building/types';
import { getBuilding } from 'store/building/actions';
import { selectBuilding } from 'store/building/selectors';
import { useEffect } from 'react';
import { configSelector } from 'store/config/selectors';

export const useBuilding = (): Building => {
  const { buildingUuid } = useSelector(configSelector);
  const dispatch = useDispatch();
  const building = useSelector(selectBuilding);

  useEffect(() => {
    if (!building && buildingUuid) {
      dispatch(getBuilding.request(buildingUuid as string));
    }
  }, [building, buildingUuid, dispatch]);

  return building;
};
