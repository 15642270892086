import React, { useEffect } from 'react';
import { useContacts, UseContactsResponse } from './useContacts.hook';
import { UserContact } from 'store/contacts/types';
import { Minus } from 'assets/icons/minus';
import { DisplayItem } from '@hqo/react-components-library/dist/molecules/display-item';
import { StyledEmptyState, AvatarContainer, GroupsIcon, LoaderWrapper } from './styles';
import { Groups } from 'assets/icons/groups';
import { useIntl, IntlShape } from 'react-intl';
import { selectBrandTheme } from 'store/theme/selectors';
import { useSelector } from 'react-redux';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';
import { BuildingTheme } from 'store/theme/types';
import { Search } from 'assets/icons/search';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import { CurrentModalView } from 'pages/calendar/types';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from 'pages/calendar/tracking';

interface Props {
  searchValue: string;
}

export const Contacts: React.FC<Props> = ({ searchValue }: Props): JSX.Element => {
  const intl: IntlShape = useIntl();
  const { contacts, contactsAreLoading, contactsHaveLoaded, deleteContact }: UseContactsResponse = useContacts({
    searchValue,
  });
  const theme: BuildingTheme = useSelector(selectBrandTheme);
  const shouldShowEmptyState: boolean =
    !contacts.length && (!contactsAreLoading || contactsHaveLoaded) && searchValue === null;
  const shouldShowNoResults: boolean = !contacts.length && searchValue !== null;
  const { handleShowModal } = useCalendarModal();

  const { handleTracking } = useTracking();

  useEffect(() => {
    handleTracking(TrackingEvents.CloseColleaguesImpression, 'impression');
  }, []);

  if (shouldShowEmptyState || shouldShowNoResults) {
    return (
      <StyledEmptyState
        dataTestId="empty-contacts"
        Icon={shouldShowEmptyState ? Groups : Search}
        title={intl.formatMessage({ id: 'emptyCloseColleagues.title' })}
        description={[
          intl.formatMessage({ id: 'closeColleagues.description1' }),
          intl.formatMessage({ id: 'closeColleagues.description2' }),
        ]}
      />
    );
  }
  if (contactsAreLoading) {
    return (
      <LoaderWrapper data-testid="loader-wrapper">
        <Loader data-testid="loader-initial" color={theme.primary_color} />
      </LoaderWrapper>
    );
  }
  return (
    <>
      {contacts.map((contact: UserContact) => (
        <DisplayItem
          key={contact.uuid}
          actions={[
            {
              icon: <Minus data-testid="remove-contact" size="lg" onClick={deleteContact(contact)} />,
            },
          ]}
          avatarIcon={
            <AvatarContainer>
              <GroupsIcon />
            </AvatarContainer>
          }
          dataTestId="closeColleagueRow"
          showShadow
          value={`${contact.first_name} ${contact.last_name}`}
          onClick={handleShowModal({
            regUserUuid: contact.uuid,
            regFirstName: contact.first_name,
            regLastName: contact.last_name,
            regShowBackButton: true,
            view: CurrentModalView.RegistrationsList,
          })}
        />
      ))}
    </>
  );
};
