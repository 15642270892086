import { useBooleanState } from '@hqo/react-components-library';
import { useTracking } from 'hooks/use-tracking';
import moment from 'moment';
import { TrackingEvents } from 'pages/calendar/tracking';
import { CalendarQueryParams, CurrentModalView } from 'pages/calendar/types';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACTION_STATUSES } from 'shared/consts';
import { RootState } from 'store';
import { createRegistrations, updateRegistration } from 'store/registrations/actions';
import { selectCreateRegistrationsStatus, selectUserRegistrations } from 'store/registrations/selectors';
import { selectBrandTheme } from 'store/theme/selectors';
import { currentUser } from 'store/user/selectors';
import { push } from '../../store/router/actions';

export const useRegisterCalendarView = (selectedDate: string) => {
  const user = useSelector(currentUser);
  const calendarViewUserUuid = user.uuid;
  const currentUserRegistrations = useSelector((state: RootState) => selectUserRegistrations(state, user?.uuid));
  const dispatch = useDispatch();
  const currentRegistration = currentUserRegistrations?.find((registration) =>
    moment(registration.date).isSame(selectedDate, 'day'),
  );
  const { value: showCancelModal, setTrue: setShowCancelModal, setFalse: setHideCancelModal } = useBooleanState(false);
  const [cancellationRegistrationUuid, setCancellationRegistrationUuid] = useState(null);
  const isRegistered = !!currentRegistration?.uuid;
  const [isPrivate, setIsPrivate] = useState(isRegistered ? !currentRegistration.private : true);
  const userRegistrationStatus = useSelector((state: RootState) => selectCreateRegistrationsStatus(state, user.uuid));
  const isLoading = userRegistrationStatus === ACTION_STATUSES.PENDING;
  const theme = useSelector(selectBrandTheme);
  const { handleTracking } = useTracking();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isRegistrationPath = queryParams.has('currentModalView');

  useEffect(() => {
    setIsPrivate(isRegistered ? !currentRegistration.private : true);
  }, [isRegistered, selectedDate]);

  const handleRegister = useCallback(() => {
    dispatch(
      createRegistrations.request({
        user_uuid: user.uuid,
        date: selectedDate,
        private: !isPrivate,
        isCurrentUser: true,
        first_name: user.first_name,
        last_name: user.last_name,
      }),
    );
    handleTracking(TrackingEvents.RegistrationClicks);
    if (isRegistrationPath) {
      const params = qs.stringify(
        {
          [CalendarQueryParams.SelectedDate]: selectedDate.substring(0, 10),
          [CalendarQueryParams.ShowBackButton]: true,
          [CalendarQueryParams.CurrentModalView]: CurrentModalView.RegistrationSuccess,
        },
        {
          skipNulls: true,
          arrayFormat: 'comma',
          addQueryPrefix: true,
          encode: false,
        },
      );
      dispatch(push(`${location.pathname}${params}`));
    }
  }, [currentUserRegistrations, selectedDate, isRegistered, isPrivate, user.uuid, isRegistrationPath]);

  const handlePrivateBool = useCallback(() => {
    setIsPrivate(!isPrivate);
    handleTracking(TrackingEvents.VisibleRegistrations);
    if (isRegistered) {
      dispatch(
        updateRegistration.request({
          user_uuid: user.uuid,
          uuid: currentRegistration.uuid,
          date: currentRegistration.date,
          private: isPrivate,
          isCurrentUser: true,
          first_name: user.first_name,
          last_name: user.last_name,
        }),
      );
    }
  }, [isPrivate, isRegistered, currentRegistration, user.uuid]);

  const handleShowCancelModal = useCallback(
    (registrationUuid: string) => () => {
      setCancellationRegistrationUuid(registrationUuid);
      setShowCancelModal();
    },
    [],
  );

  const handleHideCancelModal = useCallback(() => {
    setCancellationRegistrationUuid(null);
    setHideCancelModal();
  }, []);

  return {
    isRegistered,
    handleRegister,
    handleShowCancelModal,
    handleHideCancelModal,
    cancellationRegistrationUuid,
    showCancelModal,
    currentRegistration,
    calendarViewUserUuid,
    handlePrivateBool,
    isPrivate,
    isLoading,
    theme,
  };
};
