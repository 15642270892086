import React from 'react';
import ReactDOM from 'react-dom';

interface ModalPortalProps {
  children: React.ReactNode;
}

export const ModalPortal = ({ children }: ModalPortalProps): JSX.Element | null => {
  const modalRoot = document.getElementById('modal-root');

  if (!modalRoot) {
    // eslint-disable-next-line no-console
    console.warn('No modal-root found, using body as fallback');
  }

  return ReactDOM.createPortal(children, modalRoot ?? document.body);
};
