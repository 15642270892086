import { StyledEmptyState, StyledLoader } from 'pages/calendar/registrations-list/styles';
import React, { useEffect, useMemo } from 'react';
import { useIndividualRegistrations } from './use-individual-registrations';
import { StyledContact } from '../styles';
import { Users } from 'assets/icons/users';
import { useInfiniteScroll } from '@hqo/react-components-library/dist/hooks/use-infinite-scroll';
import { LoaderWrapper, StyledSubtitle, Wrapper } from 'pages/calendar/individual-registrations/styles';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';
import { useSelector } from 'react-redux';
import { selectBrandTheme } from 'store/theme/selectors';
import { Calendar as CalendarIcon } from 'assets/icons/calendar';
import { useIntl } from 'react-intl';
import { useTracking } from 'hooks/use-tracking';
import { TrackingEvents } from 'pages/calendar/tracking';

export const IndividualRegistrations = () => {
  const { contacts, teammates, isLoading, hasMoreTeammates, subtitle, handleGetTeammateRegistrations, isInitialLoad } =
    useIndividualRegistrations();
  const theme = useSelector(selectBrandTheme);
  const { loaderRef } = useInfiniteScroll({
    callback: handleGetTeammateRegistrations,
    hasMore: hasMoreTeammates,
  });
  const intl = useIntl();

  const { handleTracking } = useTracking();

  useEffect(() => {
    handleTracking(TrackingEvents.OtherGoingScreenImpression, 'impression');
  }, []);

  const renderTeammates = useMemo(() => {
    if (isLoading && isInitialLoad) {
      return <StyledLoader data-testid="loading-indicator" color={theme.primary_color} />;
    }
    if (contacts.length === 0 && teammates.length === 0) {
      return (
        <StyledEmptyState
          dataTestId="empty-registrations-list"
          Icon={CalendarIcon}
          title={intl.formatMessage({ id: 'noOneGoing.title' })}
          description={[
            intl.formatMessage({ id: 'noOneGoing.description1' }),
            intl.formatMessage({ id: 'noOneGoing.description2' }),
          ]}
        />
      );
    }
    return (
      <>
        <StyledSubtitle data-testid="subtitle">{subtitle}</StyledSubtitle>
        {contacts?.map((contact) => (
          <StyledContact
            key={contact.contact_uuid}
            size="lg"
            dataTestId={`contact-${contact.contact_uuid}`}
            value={`${contact.first_name} ${contact.last_name}`}
            avatarIcon={<Users />}
          />
        ))}
        {teammates?.map((teammate) => (
          <StyledContact
            key={teammate.teammate_uuid}
            size="lg"
            dataTestId={`teammate-${teammate.teammate_uuid}`}
            value={`${teammate.first_name} ${teammate.last_name}`}
          />
        ))}
      </>
    );
  }, [contacts, teammates, isLoading, isInitialLoad]);

  return (
    <Wrapper>
      {renderTeammates}
      {hasMoreTeammates && (
        <LoaderWrapper data-testid="infinite-loader-wrapper" ref={loaderRef}>
          {isLoading && !isInitialLoad && <Loader data-testid="infinite-loader" color={theme.primary_color} />}
        </LoaderWrapper>
      )}
    </Wrapper>
  );
};
