import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';
import {
  CreateContactsSuccessPayload,
  DeleteContactSuccessPayload,
  PaginatedEmployees,
  PaginatedTeammates,
  UserContact,
  UserContactsState,
} from './types';

import { ACTION_STATUSES } from 'shared/consts';
import uniqBy from 'lodash.uniqby';

export const INITIAL_STATE: UserContactsState = {
  getContacts: {
    status: null,
    error: null,
  },
  contacts: null,
  paginatedEmployees: null,
  paginatedTeammates: null,
  getPaginatedEmployees: {
    status: null,
    error: null,
  },
  getPaginatedTeammates: {
    status: null,
    error: null,
  },
  createContacts: {
    status: null,
    error: null,
  },
  deleteContact: {
    status: null,
    error: null,
  },
};

const createContactRequestHandler = (state: UserContactsState): UserContactsState => ({
  ...state,
  createContacts: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
  getContacts: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const handleCreateContactsState = (state: UserContactsState, payload: CreateContactsSuccessPayload): UserContact[] => {
  // add user contact to contacts
  const contactsCopy = [...(state?.contacts ?? [])];
  const employeeListIndex = state?.paginatedEmployees?.employees?.findIndex(
    (employee: UserContact) => employee.uuid === payload.data.contacts[0].contact_uuid,
  );
  const teammatesListIndex = state?.paginatedTeammates?.teammates?.findIndex(
    (teammate: UserContact) => teammate.uuid === payload.data.contacts[0].contact_uuid,
  );
  if (employeeListIndex >= 0) {
    contactsCopy.push(state.paginatedEmployees.employees[employeeListIndex]);
  } else if (teammatesListIndex >= 0) {
    contactsCopy.push(state.paginatedTeammates.teammates[teammatesListIndex]);
  }
  return contactsCopy;
};

const handleCreatePaginatedEmployeesState = (
  state: UserContactsState,
  payload: CreateContactsSuccessPayload,
): PaginatedEmployees => {
  if (!state?.paginatedEmployees) {
    return null;
  }
  // remove user contact from employees
  const { paginatedEmployees } = state;
  const employeesCopy = [...paginatedEmployees.employees];
  const index = employeesCopy.findIndex((item: UserContact) => item.uuid === payload.data.contacts[0].contact_uuid);
  if (index >= 0) {
    employeesCopy.splice(index, 1);
    return {
      employees: employeesCopy,
      limit: paginatedEmployees.limit,
      offset: paginatedEmployees.offset,
      total: paginatedEmployees.total - 1,
    };
  }
  return paginatedEmployees;
};

const handleCreatePaginatedTeammatesState = (
  state: UserContactsState,
  payload: CreateContactsSuccessPayload,
): PaginatedTeammates => {
  if (!state?.paginatedTeammates) {
    return null;
  }

  const { paginatedTeammates } = state;
  const teammatesCopy = [...paginatedTeammates.teammates];
  const index = teammatesCopy.findIndex(
    (teammate: UserContact) => teammate.uuid === payload.data.contacts[0].contact_uuid,
  );
  if (index >= 0) {
    teammatesCopy[index].isContact = true;
    return {
      teammates: teammatesCopy,
      limit: paginatedTeammates.limit,
      offset: paginatedTeammates.offset,
      total: paginatedTeammates.total,
    };
  }
  return paginatedTeammates;
};

const createContactSuccessHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.createContacts.success>,
): UserContactsState => ({
  ...state,
  contacts: handleCreateContactsState(state, payload),
  paginatedEmployees: handleCreatePaginatedEmployeesState(state, payload),
  paginatedTeammates: handleCreatePaginatedTeammatesState(state, payload),
  createContacts: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
  getContacts: {
    error: null,
    status: null,
  },
});

const createContactFailureHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.createContacts.failure>,
): UserContactsState => ({
  ...state,
  createContacts: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
  getContacts: {
    error: null,
    status: null,
  },
});

const getEmployeesRequestHandler = (state: UserContactsState): UserContactsState => ({
  ...state,
  getPaginatedEmployees: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getEmployeesSuccessHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.getEmployees.success>,
): UserContactsState => ({
  ...state,
  getPaginatedEmployees: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
  paginatedEmployees: {
    ...state.paginatedEmployees,
    employees:
      payload.paginatedEmployees?.isScrolling && payload.paginatedEmployees?.employees
        ? uniqBy([...state.paginatedEmployees.employees, ...payload.paginatedEmployees.employees], 'uuid')
        : uniqBy(payload.paginatedEmployees?.employees, 'uuid'),
    offset: !payload.paginatedEmployees?.offset ? 0 : payload.paginatedEmployees?.offset,
    limit: payload.paginatedEmployees?.limit,
    total: payload.paginatedEmployees?.total,
  },
});

const getEmployeesFailureHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.getEmployees.failure>,
): UserContactsState => ({
  ...state,
  getPaginatedEmployees: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getTeammatesRequestHandler = (state: UserContactsState): UserContactsState => ({
  ...state,
  getPaginatedTeammates: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getTeammatesSuccessHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.getTeammates.success>,
): UserContactsState => ({
  ...state,
  getPaginatedTeammates: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
  paginatedTeammates: {
    ...state.paginatedTeammates,
    teammates:
      payload.paginatedTeammates?.isScrolling && payload.paginatedTeammates?.teammates
        ? [...state.paginatedTeammates.teammates, ...payload.paginatedTeammates.teammates]
        : payload.paginatedTeammates?.teammates,
    offset: !payload.paginatedTeammates?.offset ? 0 : payload.paginatedTeammates?.offset,
    limit: payload.paginatedTeammates?.limit,
    total: payload.paginatedTeammates?.total,
  },
});

const getTeammatesFailureHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.getTeammates.failure>,
): UserContactsState => ({
  ...state,
  getPaginatedTeammates: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getContactsRequestHandler = (state: UserContactsState): UserContactsState => ({
  ...state,
  getContacts: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const sortBy = (key: keyof UserContact) => (a: UserContact, b: UserContact) =>
  // eslint-disable-next-line no-nested-ternary
  a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;

const getContactsSuccessHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.getContacts.success>,
): UserContactsState => ({
  ...state,
  contacts: payload.contacts.concat().sort(sortBy('first_name')),
  getContacts: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getContactsFailureHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.getContacts.failure>,
): UserContactsState => ({
  ...state,
  getContacts: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetContactsHandler = (state: UserContactsState): UserContactsState => ({
  ...state,
  getContacts: {
    status: null,
    error: null,
  },
  paginatedEmployees: null,
  paginatedTeammates: null,
  getPaginatedEmployees: {
    status: null,
    error: null,
  },
  getPaginatedTeammates: {
    status: null,
    error: null,
  },
});

const deleteContactRequestHandler = (state: UserContactsState): UserContactsState => ({
  ...state,
  deleteContact: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
  getPaginatedEmployees: {
    status: ACTION_STATUSES.PENDING,
    error: null,
  },
});

const handleDeleteContactSuccess = (state: UserContactsState, payload: DeleteContactSuccessPayload): UserContact[] => {
  const contacts: UserContact[] = [...(state?.contacts ?? [])];
  const index: number = contacts.findIndex((contact) => contact.uuid === payload.contact_uuid);
  if (index > -1) {
    contacts.splice(index, 1);
  }
  return contacts.length ? contacts : [];
};

const handleDeletePaginatedTeammateSuccess = (
  state: UserContactsState,
  payload: DeleteContactSuccessPayload,
): PaginatedTeammates => {
  if (!state.paginatedTeammates) {
    return null;
  }
  const { paginatedTeammates } = state;
  const teammatesCopy = [...paginatedTeammates.teammates];
  const index = teammatesCopy.findIndex((teammate: UserContact) => teammate.uuid === payload.contact_uuid);
  if (index >= 0) {
    teammatesCopy[index].isContact = false;
    return {
      teammates: teammatesCopy,
      limit: paginatedTeammates.limit,
      offset: paginatedTeammates.offset,
      total: paginatedTeammates.total,
    };
  }
  return paginatedTeammates;
};

const handleDeletePaginatedEmployeesSuccess = (
  state: UserContactsState,
  payload: DeleteContactSuccessPayload,
  contact: UserContact,
): PaginatedEmployees => {
  if (!state.paginatedEmployees) {
    return null;
  }
  const { paginatedEmployees } = state;
  const paginatedEmployeesCopy = [...paginatedEmployees.employees];
  const index = paginatedEmployeesCopy.findIndex((teammate: UserContact) => teammate.uuid === payload.contact_uuid);
  if (index < 0) {
    paginatedEmployeesCopy.push(contact);
    paginatedEmployeesCopy.sort((a, b) => a.first_name.localeCompare(b.first_name));
  }
  return {
    employees: paginatedEmployeesCopy,
    limit: paginatedEmployees.limit,
    offset: paginatedEmployees.offset,
    total: index < 0 ? paginatedEmployees.total + 1 : paginatedEmployees.total,
  };
};

const deleteContactSuccessHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.deleteContact.success>,
): UserContactsState => {
  const contactToDelete = state.contacts.find((contact) => contact.uuid === payload.contact_uuid);
  return {
    ...state,
    deleteContact: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
    contacts: handleDeleteContactSuccess(state, payload),
    paginatedTeammates: handleDeletePaginatedTeammateSuccess(state, payload),
    paginatedEmployees: handleDeletePaginatedEmployeesSuccess(state, payload, contactToDelete),
    getPaginatedEmployees: {
      status: null,
      error: null,
    },
  };
};

const deleteContactFailureHandler = (
  state: UserContactsState,
  { payload }: ActionType<typeof actions.deleteContact.failure>,
): UserContactsState => ({
  ...state,
  deleteContact: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
  getPaginatedEmployees: {
    status: null,
    error: null,
  },
});

const UserContactsReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.createContacts.request, createContactRequestHandler)
  .handleAction(actions.createContacts.success, createContactSuccessHandler)
  .handleAction(actions.createContacts.failure, createContactFailureHandler)
  .handleAction(actions.getContacts.request, getContactsRequestHandler)
  .handleAction(actions.getContacts.success, getContactsSuccessHandler)
  .handleAction(actions.getContacts.failure, getContactsFailureHandler)
  .handleAction(actions.getEmployees.request, getEmployeesRequestHandler)
  .handleAction(actions.getEmployees.success, getEmployeesSuccessHandler)
  .handleAction(actions.getEmployees.failure, getEmployeesFailureHandler)
  .handleAction(actions.getTeammates.request, getTeammatesRequestHandler)
  .handleAction(actions.getTeammates.success, getTeammatesSuccessHandler)
  .handleAction(actions.getTeammates.failure, getTeammatesFailureHandler)
  .handleAction(actions.resetContacts, resetContactsHandler)
  .handleAction(actions.deleteContact.request, deleteContactRequestHandler)
  .handleAction(actions.deleteContact.success, deleteContactSuccessHandler)
  .handleAction(actions.deleteContact.failure, deleteContactFailureHandler);

export default UserContactsReducer;
