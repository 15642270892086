import { Navigate } from 'react-router-dom';
import React from 'react';
import { hasToken } from 'store/user/selectors';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useSelector } from 'react-redux';
import { CalendarLoadingSkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { SkeletonContainer, CancelIcon } from 'hocs/shared-styles';
import { useSdk } from 'hooks/use-sdk.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';

interface PrivateProps {
  redirectTo?: string;
  children: React.ReactNode;
}
export const Private: React.FC<PrivateProps> = ({
  redirectTo = '/error',
  children,
}: PrivateProps): JSX.Element | null => {
  const [currentUser, loading] = useCurrentUser();
  const isTokenStored = useSelector(hasToken);
  const { handleCloseApp } = useSdk();
  const isSmallViewportWidth = useIsSmallViewportWidth();

  if (currentUser) {
    // Wrapped in a React Fragment intentionally
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  if (!isTokenStored && !loading) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <SkeletonContainer data-testid="private-skeleton-load">
      {isSmallViewportWidth && <CancelIcon onClick={handleCloseApp} data-testid="cancel-icon" />}
      <CalendarLoadingSkeleton />
    </SkeletonContainer>
  );
};
