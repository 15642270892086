import React, { useMemo } from 'react';
import { useEmployees, UseEmployeesResponse } from './useEmployees.hook';
import { UserContact } from 'store/contacts/types';
import { DisplayItem } from '@hqo/react-components-library/dist/molecules/display-item';
import { useInfiniteScroll } from '@hqo/react-components-library/dist/hooks/use-infinite-scroll';
import { Loader } from '@hqo/react-components-library/dist/atoms/loader';
import { selectBrandTheme } from 'store/theme/selectors';
import { useSelector } from 'react-redux';
import { LoaderWrapper, StyledEmptyState, StyledAddPersonIcon } from './styles';
import { BuildingTheme } from 'store/theme/types';
import { Search } from 'assets/icons/search';
import { useIntl, IntlShape } from 'react-intl';
import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import { CurrentModalView } from 'pages/calendar/types';

interface Props {
  searchValue: string;
}

export const Employees: React.FC<Props> = ({ searchValue }: Props): JSX.Element => {
  const {
    addContact,
    isInitialLoad,
    isResetting,
    employees,
    employeesAreLoading,
    total,
    infiniteScrollCallback,
  }: UseEmployeesResponse = useEmployees({ searchValue });
  const intl: IntlShape = useIntl();
  const theme: BuildingTheme = useSelector(selectBrandTheme);
  const { loaderRef } = useInfiniteScroll({
    callback: infiniteScrollCallback,
    hasMore: total > employees.length,
  });
  const shouldShowInitialLoader: boolean = (employeesAreLoading && isInitialLoad) || isResetting;
  const shouldShowInfinteLoader: boolean = employeesAreLoading && !isInitialLoad && !isResetting;
  const shouldShowEmptyState: boolean = employees.length === 0 && searchValue !== null;
  const { handleShowModal } = useCalendarModal();

  const renderEmployees = useMemo(() => {
    if (shouldShowInitialLoader) {
      return (
        <LoaderWrapper data-testid="loader-wrapper">
          <Loader data-testid="loader-initial" color={theme.primary_color} />
        </LoaderWrapper>
      );
    }
    if (shouldShowEmptyState) {
      return (
        <StyledEmptyState
          dataTestId="empty-contacts"
          Icon={Search}
          title={intl.formatMessage({ id: 'emptyCloseColleagues.teammatesTitle' })}
          description={[
            intl.formatMessage({ id: 'emptyCloseColleagues.teammatesDescription1' }),
            intl.formatMessage({ id: 'emptyCloseColleagues.teammatesDescription2' }),
          ]}
        />
      );
    }
    return employees.map((employee: UserContact) => (
      <DisplayItem
        key={employee.uuid}
        actions={[
          {
            icon: <StyledAddPersonIcon data-testid="add-contact" size="lg" onClick={addContact(employee)} />,
          },
        ]}
        dataTestId="teammateRow"
        showShadow
        value={`${employee.first_name} ${employee.last_name}`}
        onClick={handleShowModal({
          regUserUuid: employee.uuid,
          regFirstName: employee.first_name,
          regLastName: employee.last_name,
          regShowBackButton: true,
          view: CurrentModalView.RegistrationsList,
        })}
      />
    ));
  }, [employees, theme.primary_color, addContact, shouldShowEmptyState, shouldShowInitialLoader]);
  return (
    <>
      {renderEmployees}
      <LoaderWrapper data-testid="infinite-loader-wrapper" ref={loaderRef}>
        {shouldShowInfinteLoader && <Loader data-testid="infinite-loader-initial" color={theme.primary_color} />}
      </LoaderWrapper>
    </>
  );
};
