import { Modal } from '@hqo/react-components-library/dist/molecules/modals';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import styled from 'styled-components';
import { ArrowLeftChevron } from '@hqo/react-components-library/dist/icons';
import { zIndex } from 'shared/z-index';

export const StyledSwipeModal = styled(SwipeModal)`
  #scroll-container {
    padding-right: 6px;
    padding-left: 6px;
    margin-left: -3px;
  }

  .content-body {
    height: calc(100% - 76px);
  }
  .modal-title {
    padding-bottom: 24px;
    color: ${({ theme }) => theme.colors.greys.darkGrey7};
    line-height: 33px;
  }
`;

export const StyledModal = styled(Modal)`
  z-index: ${zIndex('popover')};
  & > div:first-child {
    width: 768px;
    height: 664px;
  }
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  padding-bottom: 24px;
`;

export const StyledArrowLeft = styled(ArrowLeftChevron)`
  position: absolute;
  top: 12px;
  left: 14px;
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
`;
