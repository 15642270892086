import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import React, { useMemo, useEffect } from 'react';
import { CurrentModalView } from '../types';
import { RegistrationsList } from '../registrations-list/registrations-list';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ModalTitle, StyledArrowLeft, StyledModal, StyledSwipeModal } from './styles';
import moment from 'moment-timezone';
import { selectLocale } from 'store/config/selectors';
import { IndividualRegistrations } from '../individual-registrations/individual-registrations';
import { CloseColleagues } from '../close-colleagues/close-colleagues';
import { RegistrationSuccess } from '../registration-success/registration-success';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';

moment.tz.setDefault('Etc/UTC');

export const CalendarModal = () => {
  const {
    handleCloseRegistrations,
    handleGoBack,
    currentModalView,
    firstName,
    lastName,
    selectedDate,
    isCurrentUser,
    showBackButton,
  } = useCalendarModal();
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  useEffect(() => {
    moment.locale(locale);
  }, [locale]);
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const title = useMemo(() => {
    switch (currentModalView) {
      case CurrentModalView.RegistrationSuccess:
        return '';
      case CurrentModalView.IndividualRegistrations:
        return moment(selectedDate).format('dddd, LL');
      case CurrentModalView.RegistrationsList:
        return isCurrentUser
          ? intl.formatMessage({ id: 'yourRegistrations' })
          : `${firstName} ${lastName} ${intl.formatMessage({ id: 'registrations' })}`;
      case CurrentModalView.CloseColleagues:
        return intl.formatMessage({ id: 'closeColleagues.title' });
      default:
        return null;
    }
  }, [firstName, lastName, isCurrentUser, currentModalView]);

  const selectView = useMemo(() => {
    switch (currentModalView) {
      case CurrentModalView.RegistrationSuccess:
        return <RegistrationSuccess />;
      case CurrentModalView.IndividualRegistrations:
        return <IndividualRegistrations />;
      case CurrentModalView.RegistrationsList:
        return <RegistrationsList />;
      case CurrentModalView.Search:
      case CurrentModalView.CloseColleagues:
        return <CloseColleagues />;
      default:
        return null;
    }
  }, [currentModalView]);

  if (!isSmallViewportWidth) {
    return (
      <StyledModal data-testid="large-modal" opened onClose={handleCloseRegistrations}>
        <>
          {showBackButton && <StyledArrowLeft onClick={handleGoBack} size="sm" />}
          <ModalTitle>{title}</ModalTitle>
          {selectView}
        </>
      </StyledModal>
    );
  }

  return (
    <StyledSwipeModal
      content={selectView}
      title={title}
      onClose={handleCloseRegistrations}
      reverseAnimation={false}
      withLeftArrowButton={showBackButton}
      onPressBack={handleGoBack}
    />
  );
};
