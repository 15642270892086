import { Wrapper } from './error.styles';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ErrorNotification } from '@hqo/react-components-library/dist/molecules/errorNotification';
import { useMiniappSdk } from '../../components/miniapp-sdk-provider/miniapp-sdk.hooks';

export const ErrorPage = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const miniappSdk = useMiniappSdk();

  useEffect(() => {
    miniappSdk.header.showHeader();
  }, []);

  const handleSupportEmailClick = useCallback(() => {
    window.location.href = 'mailto:support@hqo.co';
  }, []);

  return (
    <Wrapper>
      <ErrorNotification
        title={formatMessage({ id: 'notifications.genericErrors.contactSupport.title' })}
        description={[
          formatMessage({ id: 'notifications.genericErrors.contactSupport.description1' }),
          formatMessage({ id: 'notifications.genericErrors.contactSupport.description2' }),
        ]}
        buttonText={formatMessage({ id: 'notifications.genericErrors.contactSupport.buttonText' })}
        onClick={handleSupportEmailClick}
      />
    </Wrapper>
  );
};
