/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const Search: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9363 10.0623H11.21L10.9575 9.81168C11.8494 8.7716 12.3918 7.42325 12.3918 5.94592C12.3918 2.66194 9.72989 0 6.44592 0C3.16194 0 0.5 2.66194 0.5 5.94592C0.5 9.22989 3.16194 11.8918 6.44592 11.8918C7.92325 11.8918 9.27068 11.3503 10.3108 10.4593L10.5632 10.71V11.4345L15.1352 16L16.4991 14.6361L11.9363 10.0623ZM6.44592 10.0623C4.17183 10.0623 2.32951 8.22 2.32951 5.94592C2.32951 3.67275 4.17183 1.82951 6.44592 1.82951C8.71908 1.82951 10.5623 3.67275 10.5623 5.94592C10.5623 8.22 8.71908 10.0623 6.44592 10.0623Z"
      fill="#696E72"
    />
  </SvgIcon>
);

export default Search;
