import { RootState } from '../reducer';
import { UserContactsState } from './types';
import { createSelector } from 'reselect';
import { ACTION_STATUSES } from 'shared/consts';

export const contactsStateSelector = (state: RootState): UserContactsState => state.contacts;

export const selectUserContacts = createSelector([contactsStateSelector], (contacts) => contacts.contacts ?? []);

export const selectCreateContactsStatus = createSelector(
  [contactsStateSelector],
  (contacts) => contacts.createContacts?.status,
);

export const selectDeleteContactsStatus = createSelector(
  [contactsStateSelector],
  (contacts) => contacts.deleteContact?.status,
);

export const selectGetContactsStatus = createSelector(
  [contactsStateSelector],
  (contacts) => contacts.getContacts?.status ?? null,
);

export const selectGetEmployeesStatus = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.getPaginatedEmployees?.status ?? null,
);

export const selectGetTeammatesStatus = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.getPaginatedTeammates?.status ?? null,
);

export const selectEmployees = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedEmployees?.employees ?? [],
);

export const selectTeammates = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedTeammates?.teammates ?? [],
);

export const selectShouldFetchContacts = createSelector(
  [selectGetContactsStatus, selectUserContacts],
  (status, contacts) => status === null && !contacts.length,
);

export const selectShouldFetchEmployees = createSelector(
  [selectGetEmployeesStatus, selectEmployees],
  (status, teammates) => status === null && !teammates.length,
);

export const selectContactsAreLoading = createSelector(
  [selectGetContactsStatus],
  (status) => status === ACTION_STATUSES.PENDING,
);

export const selectContactsHaveLoaded = createSelector(
  [selectGetContactsStatus],
  (status) => status === ACTION_STATUSES.FULFILLED || status === ACTION_STATUSES.REJECTED,
);

export const selectEmployeesAreLoading = createSelector(
  [selectGetEmployeesStatus],
  (status) => status === ACTION_STATUSES.PENDING,
);

export const selectEmployeesPaginationLimit = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedEmployees?.limit ?? 10,
);

export const selectEmployeesPaginationOffset = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedEmployees?.offset ?? null,
);

export const selectEmployeesPaginationTotal = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedEmployees?.total ?? null,
);

export const selectTeammatesAreLoading = createSelector(
  [selectGetTeammatesStatus],
  (status) => status === ACTION_STATUSES.PENDING,
);

export const selectTeammatesPaginationLimit = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedTeammates?.limit ?? 10,
);

export const selectTeammatesPaginationOffset = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedTeammates?.offset ?? null,
);

export const selectTeammatesPaginationTotal = createSelector(
  [contactsStateSelector],
  (contacts) => contacts?.paginatedTeammates?.total ?? null,
);
