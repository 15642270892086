import React from 'react';
import { Router } from 'components/router';
import { useAnalytics } from 'hooks/use-analytics.hook';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { CalendarLoadingSkeleton } from '@hqo/react-components-library/dist/molecules/loading-skeleton';
import { SkeletonContainer, CancelIcon } from 'hocs/shared-styles';
import { useSdk } from 'hooks/use-sdk.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';

export const App: React.FC = (): JSX.Element => {
  const { handleCloseApp } = useSdk();
  const shouldWaitForLogin = useExternalLogin();
  useAnalytics();
  const isSmallViewportWidth = useIsSmallViewportWidth();

  if (shouldWaitForLogin) {
    return (
      <SkeletonContainer data-testid="app-skeleton-load">
        {isSmallViewportWidth && <CancelIcon onClick={handleCloseApp} data-testid="cancel-icon" />}
        <CalendarLoadingSkeleton />
      </SkeletonContainer>
    );
  }

  return <Router />;
};
