import { MobileModal } from './mobile-modal';
import { ModalPortal } from './modal-portal';
import React from 'react';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';
import { ModalProps } from 'components/modal/modal.interface';
import { ModalContent } from './modal-content';

const maxWidthVariants: Record<NonNullable<ModalProps['variant']>, number> = {
  small: 424,
  normal: 612,
  wide: 984,
};

export const Modal = ({
  children,
  onClose: handleClose,
  hideMobileCloseButton,
  variant = 'normal',
  withExitButton = true,
}: ModalProps): JSX.Element => {
  const isSmallViewportWidth = useIsSmallViewportWidth();
  return (
    <ModalPortal>
      {isSmallViewportWidth ? (
        <MobileModal onClose={handleClose} hideMobileCloseButton={hideMobileCloseButton}>
          {children}
        </MobileModal>
      ) : (
        <ModalContent
          maxWidth={maxWidthVariants[variant]}
          onClose={handleClose}
          variant={variant}
          withExitButton={withExitButton}
        >
          {children}
        </ModalContent>
      )}
    </ModalPortal>
  );
};
