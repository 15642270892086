import { CancelRegistrationsDialog } from 'components/cancel-registrations-dialog';
import { useRegisterCalendarView } from 'components/register-calendar-view/use-register-calendar-view';
import React from 'react';
import { useIntl } from 'react-intl';
import {
  ButtonContainer,
  Container,
  RegistrationContainer,
  StyledButton,
  StyledSwitch,
} from './register-calendar-view.styles';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/device';

interface RegisterCalendarViewProps {
  selectedDate: string;
}

export const RegisterCalendarView: React.FC<RegisterCalendarViewProps> = ({ selectedDate }) => {
  const {
    isRegistered,
    handleRegister,
    handleShowCancelModal,
    handleHideCancelModal,
    cancellationRegistrationUuid,
    showCancelModal,
    currentRegistration,
    handlePrivateBool,
    isPrivate,
  } = useRegisterCalendarView(selectedDate);
  const intl = useIntl();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  return (
    <>
      <Container data-testid="register-calendar-view">
        <RegistrationContainer>
          <span>{intl.formatMessage({ id: 'registrationCalendarView.visible' })}</span>
          <StyledSwitch data-testid="register-calendar-view-switch" checked={isPrivate} onChange={handlePrivateBool} />
        </RegistrationContainer>
        <ButtonContainer
          isSmallViewportWidth={isSmallViewportWidth}
          data-testid="register-calendar-view-button-container"
        >
          {isRegistered ? (
            <StyledButton
              data-testid="register-calendar-view-cancel-button"
              variant="secondary"
              text={intl.formatMessage({ id: 'registrationCalendarView.cancelButtonText' })}
              onClick={handleShowCancelModal(currentRegistration?.uuid)}
            />
          ) : (
            <StyledButton
              data-testid="register-calendar-view-register-button"
              variant="primary"
              text={intl.formatMessage({ id: 'registrationCalendarView.goingButtonText' })}
              onClick={handleRegister}
            />
          )}
        </ButtonContainer>
      </Container>
      <CancelRegistrationsDialog
        registrationUuid={cancellationRegistrationUuid}
        showCancelModal={showCancelModal}
        handleHideCancelModal={handleHideCancelModal}
      />
    </>
  );
};
