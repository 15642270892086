import { FailureActionPayload } from '../errors/types';

export interface Contact {
  contact_uuid: string;
  date: string;
  private: boolean;
  first_name: string;
  last_name: string;
  description: string;
  job_title: string;
  email: string;
}

export interface Teammate {
  teammate_uuid: string;
  date: string;
  private: boolean;
  first_name: string;
  last_name: string;
  description: string;
  job_title: string;
  email: string;
}

export interface UserRegistration {
  uuid: string;
  user_uuid: string;
  date: string;
  private: boolean;
  contacts: Contact[];
  teammate_count?: number;
  first_name?: string;
  last_name?: string;
}

export interface TeammateRegistration {
  teammates: Teammate[];
}

export enum TeammateRegistrationView {
  Calendar = 'calendar-teammates',
  RegistrationsList = 'registrations-list-teammates',
  IndividualRegistrations = 'individual-registrations-teammates',
}

export interface TeammateRegistrationPayload {
  user_uuid: string;
  date: string;
  offset: number;
  limit: number;
  view: TeammateRegistrationView;
}

export interface TeammateRegistrationArgs extends TeammateRegistrationPayload {
  company_uuid: string;
  building_uuid: string;
}
export interface TeammateRegistrationSuccessPayload {
  user_uuid: string;
  limit: number;
  offset: number;
  total: number;
  view: TeammateRegistrationView;
  teammates: Teammate[];
}

export interface TeammateRegistrationSuccessReponse {
  data: {
    teammates: Teammate[];
    count: number;
    offset: number;
    total: number;
  };
}

export interface TeammateRegistrationFailurePayload extends FailureActionPayload {
  user_uuid: string;
  view: TeammateRegistrationView;
}

export interface TeammateRegistrationState {
  teammates: Teammate[];
  limit: number;
  offset: number;
  total: number;
  getTeammateRegistrations: AsyncActionTypes;
}

export interface CreateRegistration {
  date: string;
  private: boolean;
}

export type UserRegistrations = {
  [key in TeammateRegistrationView]?: TeammateRegistrationState;
} & {
  registrations: UserRegistration[];
  tempRegistrations?: UserRegistration[];
  getRegistrations: AsyncActionTypes;
  createRegistrations: AsyncActionTypes;
  deleteRegistration: AsyncActionTypes;
  updateRegistration: AsyncActionTypes;
};

export interface UserRegistrationsState {
  [key: string]: UserRegistrations;
}

export interface GetUserRegistrationsPayload {
  user_uuid: string;
  start_date: string;
  timeframe?: TimeframeEnum;
  isCurrentUser?: boolean;
}

export interface GetUserRegistrationsSuccessResponse {
  data: {
    registrations: UserRegistration[];
  };
}

export interface CreateRegistrationsSuccessResponse {
  data: {
    registrations: UserRegistration[];
  };
}

export interface GetUserRegistrationsSuccessPayload {
  registrations: UserRegistration[];
  user_uuid: string;
  isCurrentUser?: boolean;
}

export interface GetUserRegistrationsFailurePayload extends FailureActionPayload {
  user_uuid: string;
}

export interface GetUserRegistrationsArgs extends GetUserRegistrationsPayload {
  building_uuid: string;
  company_uuid: string;
}

export interface CreateRegistrationsArgs extends CreateRegistration {
  building_uuid: string;
  company_uuid: string;
  user_uuid: string;
  first_name?: string;
  last_name?: string;
}
export interface CreateRegistrationsPayload {
  user_uuid: string;
  date: string;
  private: boolean;
  isCurrentUser?: boolean;
  first_name?: string;
  last_name?: string;
}

export interface CreateRegistrationsSuccessPayload {
  user_uuid: string;
  isCurrentUser?: boolean;
  data: {
    registrations: UserRegistration[];
  };
}

export interface CreateRegistrationsFailurePayload extends FailureActionPayload {
  user_uuid: string;
}

export interface DeleteRegistrationPayload {
  user_uuid: string;
  registration_uuid: string;
  isCurrentUser?: boolean;
}

export interface DeleteRegistrationSuccessPayload {
  user_uuid: string;
  registration_uuid: string;
  isCurrentUser?: boolean;
}

export interface DeleteRegistrationFailurePayload extends FailureActionPayload {
  user_uuid: string;
  registration_uuid: string;
}

export interface DeleteRegistrationArgs extends DeleteRegistrationPayload {
  building_uuid: string;
  company_uuid: string;
}

export interface UpdateRegistrationsPayload {
  user_uuid: string;
  uuid: string;
  date: string;
  private: boolean;
  isCurrentUser?: boolean;
  first_name?: string;
  last_name?: string;
}

export interface UpdateRegistrationsSuccessPayload {
  user_uuid: string;
  uuid: string;
  date: string;
  private: boolean;
  isCurrentUser?: boolean;
  first_name?: string;
  last_name?: string;
}

export interface UpdateRegistrationsFailurePayload extends FailureActionPayload {
  user_uuid: string;
}

export interface UpdateRegistrationArgs extends UpdateRegistrationsPayload {
  building_uuid: string;
  company_uuid: string;
}

export enum TimeframeEnum {
  WEEK = 'week',
  ALL = 'all',
}

export interface ResetTeammateRegistrationPayload {
  userUuid: string;
  view: TeammateRegistrationView;
}
