import * as altBuildingsActions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { AltBuildingsState } from './types';

export const initialState: AltBuildingsState = {
  altBuildings: null,
  getAltBuildings: {
    status: null,
    error: null,
  },
  currentAltBuilding: null,
};

const getAltBuildingsRequestHandler = (state: AltBuildingsState): AltBuildingsState => ({
  ...state,
  getAltBuildings: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getAltBuildingsSuccessHandler = (
  state: AltBuildingsState,
  { payload }: ActionType<typeof altBuildingsActions.getAltBuildings.success>,
): AltBuildingsState => ({
  ...state,
  altBuildings: payload,
  getAltBuildings: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getAltBuildingsFailureHandler = (
  state: AltBuildingsState,
  { payload }: ActionType<typeof altBuildingsActions.getAltBuildings.failure>,
): AltBuildingsState => ({
  ...state,
  getAltBuildings: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const setCurrentAltBuildingHandler = (
  state: AltBuildingsState,
  { payload }: ActionType<typeof altBuildingsActions.setCurrentAltBuilding>,
): AltBuildingsState => ({
  ...state,
  currentAltBuilding: payload,
});

const altBuildingsReducer = createReducer(initialState)
  .handleAction(altBuildingsActions.getAltBuildings.request, getAltBuildingsRequestHandler)
  .handleAction(altBuildingsActions.getAltBuildings.success, getAltBuildingsSuccessHandler)
  .handleAction(altBuildingsActions.getAltBuildings.failure, getAltBuildingsFailureHandler)
  .handleAction(altBuildingsActions.setCurrentAltBuilding, setCurrentAltBuildingHandler);

export default altBuildingsReducer;
