import { useCalendarModal } from 'pages/calendar/calendar-modal/use-calendar-modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  selectUserRegistrationsStatus,
  selectUserTempRegistrations,
  selectTeammmateRegistrationStatus,
  selectTeammateRegistrations,
} from 'store/registrations/selectors';
import { currentUser } from 'store/user/selectors';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ACTION_STATUSES } from 'shared/consts';
import {
  getUserRegistrations,
  getTeammateRegistrations,
  resetTeammateRegistrations,
} from 'store/registrations/actions';
import { useIntl } from 'react-intl';
import { selectLocale } from 'store/config/selectors';
import { TeammateRegistrationView } from 'store/registrations/types';
import { selectBuilding } from 'store/building/selectors';

export const useIndividualRegistrations = () => {
  const user = useSelector(currentUser);
  const building = useSelector(selectBuilding);
  const { selectedDate } = useCalendarModal();
  const userRegistrationStatus = useSelector((state: RootState) => selectUserRegistrationsStatus(state, user.uuid));
  const teammateRegistrationStatus = useSelector((state: RootState) =>
    selectTeammmateRegistrationStatus(state, user.uuid, TeammateRegistrationView.IndividualRegistrations),
  );
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const isLoading =
    userRegistrationStatus === ACTION_STATUSES.PENDING ||
    teammateRegistrationStatus === ACTION_STATUSES.PENDING ||
    !userRegistrationStatus ||
    !teammateRegistrationStatus;

  useEffect(() => {
    moment.locale(locale);
  }, [locale]);

  const tempUserRegistrations = useSelector((state: RootState) => selectUserTempRegistrations(state, user.uuid));
  const registrationsToUse = tempUserRegistrations;
  const userContacts = registrationsToUse.find((registration) =>
    moment(registration.date).isSame(selectedDate, 'day'),
  )?.contacts;

  const teammateRegistrations = useSelector((state: RootState) =>
    selectTeammateRegistrations(state, user.uuid, TeammateRegistrationView.IndividualRegistrations),
  );
  const dispatch = useDispatch();
  const teammates = teammateRegistrations?.teammates;

  const handleGetTeammateRegistrations = useCallback(() => {
    dispatch(
      getTeammateRegistrations.request({
        user_uuid: user.uuid,
        date: moment(selectedDate).format('YYYY-MM-DD'),
        offset: teammateRegistrations?.teammates.length || 0,
        limit: 10,
        view: TeammateRegistrationView.IndividualRegistrations,
      }),
    );
  }, [teammateRegistrations?.teammates?.length, selectedDate, teammates]);

  useEffect(() => {
    dispatch(
      getUserRegistrations.request({
        user_uuid: user.uuid,
        start_date: moment(selectedDate).format('YYYY-MM-DD'),
        isCurrentUser: true,
      }),
    );
  }, [user.uuid]);

  useEffect(() => {
    handleGetTeammateRegistrations();
  }, []);

  useEffect(
    () => () => {
      dispatch(
        resetTeammateRegistrations({ userUuid: user.uuid, view: TeammateRegistrationView.IndividualRegistrations }),
      );
    },
    [user.uuid],
  );

  const hasMoreTeammates = teammateRegistrations?.teammates?.length < teammateRegistrations?.total;
  const userContactsCount = userContacts?.length || 0;
  const teammateCount = teammateRegistrations?.total || 0;
  const totalCount = userContactsCount + teammateCount;

  const subtitle = useMemo(() => {
    const selectedDaysRegistration = tempUserRegistrations.find((registration) =>
      moment(registration.date).isSame(selectedDate, 'day'),
    );
    const isCurrentUserGoing = selectedDaysRegistration?.uuid;

    return isCurrentUserGoing
      ? intl.formatMessage(
          { id: 'youAndTeammatesGoing' },
          {
            count: totalCount,
          },
        )
      : intl.formatMessage(
          { id: 'teammatesGoingInBuilding' },
          {
            count: totalCount, buildingName: building.name,
          },
        );
  }, [tempUserRegistrations, selectedDate, totalCount]);

  useEffect(() => {
    if (isInitialLoad && !isLoading) {
      setIsInitialLoad(false);
    }
  }, [isLoading]);

  return {
    contacts: userContacts,
    teammates: teammateRegistrations?.teammates || [],
    isLoading,
    selectedDate,
    hasMoreTeammates,
    totalCount,
    handleGetTeammateRegistrations,
    subtitle,
    isInitialLoad,
    locale,
  };
};
