export enum TrackingEvents {
  MainScreenImpression = 'who_is_in_main_screen_impression',
  SearchScreenImpression = 'who_is_in_search_screen_impression',
  CloseColleaguesImpression = 'who_is_in_close_colleagues_screen_impression',
  YourRegistrationsImpression = 'who_is_in_registrations_screen_impression',
  OtherRegistrationImpression = 'who_is_in_others_registrations_screen_impressions',
  OtherGoingScreenImpression = 'who_is_in_others_going_screen_impression',
  RegistrationClicks = 'who_is_in_registration_clicks',
  CancelRegistrationClicks = 'who_is_in_cancel_registration_click',
  AddCloseColleagues = 'who_is_in_close_colleagues_add_clicks',
  RemoveColleagues = 'who_is_in_close_colleagues_remove_clicks',
  DayClick = 'who_is_in_day_click',
  NextWeekClick = 'who_is_in_next_week_click',
  PreviousWeekClick = 'who_is_in_previous_week_click',
  PrivateRegistration = 'who_is_in_private_toggle_click',
  VisibleRegistrations = 'who_is_in_visible_toggle_click',
  AddToTeam = 'who_is_in_add_to_your_team_click',
}
