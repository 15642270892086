import styled from 'styled-components';
import { Confirmed } from '@hqo/react-components-library/dist/icons';
import { Button } from '@hqo/react-components-library';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const CheckContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const StyledTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  font-family: ${({ theme }) => theme.fonts.join()};
`;

export const StyledSubtitle = styled.p`
  font-size: 17px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  font-family: ${({ theme }) => theme.fonts.join()};
  margin: 2px;
`;

export const StyledConfirm = styled(Confirmed)`
  color: ${({ theme }) => theme.colors.admin.green};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  padding: 13px 16px;

  margin-bottom: 20px;
`;
