import { AjaxResponse, ajax } from 'rxjs/ajax';
import {
  CreateContactsArgs,
  CreateContactsSuccessResponse,
  DeleteContactArgs,
  GetContactsArgs,
  GetContactsSuccessResponse,
  GetEmployeesArgs,
} from '../contacts/types';
import {
  CreateRegistrationsArgs,
  CreateRegistrationsSuccessResponse,
  DeleteRegistrationArgs,
  GetUserRegistrationsArgs,
  GetUserRegistrationsSuccessResponse,
  TeammateRegistrationArgs,
  TeammateRegistrationSuccessReponse,
  UpdateRegistrationArgs,
  UpdateRegistrationsSuccessPayload,
} from 'store/registrations/types';

import { AltBuilding } from 'store/alt-buildings/types';
import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CurrentUserResponse } from 'store/user/types';
import { Observable } from 'rxjs';
import { OwnerParams } from 'shared/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { VISITOR_REGISTRATION } from 'shared/consts';
import moment from 'moment';
import qs from 'qs';

export class ApiClient {
  constructor(private baseUrl: string, private authToken?: string | null, private altBuildingUuid?: string | null) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'If-None-Match': null,
      ...(this.altBuildingUuid ? { 'alt-building-uuid': this.altBuildingUuid } : {}),
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}?include=languages`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<AltBuilding[]>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${this.baseUrl}/api/verticals/${VISITOR_REGISTRATION}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  getRegistrations({
    building_uuid,
    company_uuid,
    user_uuid,
    start_date,
    timeframe,
  }: GetUserRegistrationsArgs): Observable<AjaxResponse<GetUserRegistrationsSuccessResponse>> {
    const params = qs.stringify(
      {
        start_date: moment(start_date).utc().startOf('day').format('YYYY-MM-DD'),
        timeframe,
      },
      {
        skipNulls: true,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        encode: false,
      },
    );
    return ajax.get(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/companies/${company_uuid}/buildings/${building_uuid}/users/${user_uuid}/registrations${params}`,
      this.DefaultHeaders,
    );
  }

  getTeammateRegistrations({
    building_uuid,
    company_uuid,
    user_uuid,
    date,
    limit,
    offset,
  }: TeammateRegistrationArgs): Observable<AjaxResponse<TeammateRegistrationSuccessReponse>> {
    const params = qs.stringify(
      {
        date: moment(date).utc().startOf('day').format('YYYY-MM-DD'),
        limit,
        offset,
      },
      {
        skipNulls: true,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        encode: false,
      },
    );
    return ajax.get(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/companies/${company_uuid}/buildings/${building_uuid}/users/${user_uuid}/registrations/teammates${params}`,
      this.DefaultHeaders,
    );
  }

  createRegistrations({
    user_uuid,
    building_uuid,
    company_uuid,
    date,
    private: boolean,
    first_name,
    last_name,
  }: CreateRegistrationsArgs): Observable<AjaxResponse<CreateRegistrationsSuccessResponse>> {
    return ajax.post(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/companies/${company_uuid}/buildings/${building_uuid}/users/${user_uuid}/registrations`,
      { registrations: [{ date, private: boolean, first_name, last_name }] },
      this.DefaultHeaders,
    );
  }

  updateRegistration({
    user_uuid,
    building_uuid,
    company_uuid,
    uuid,
    date,
    private: boolean,
    first_name,
    last_name,
  }: UpdateRegistrationArgs): Observable<AjaxResponse<UpdateRegistrationsSuccessPayload>> {
    return ajax.put(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/companies/${company_uuid}/buildings/${building_uuid}/users/${user_uuid}/registrations`,
      { registrations: [{ uuid, date, user_uuid, private: boolean, first_name, last_name }] },
      this.DefaultHeaders,
    );
  }

  deleteRegistration({ user_uuid, building_uuid, company_uuid, registration_uuid }: DeleteRegistrationArgs) {
    return ajax.delete(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/companies/${company_uuid}/buildings/${building_uuid}/users/${user_uuid}/registrations/${registration_uuid}`,
      this.DefaultHeaders,
    );
  }

  createContacts({
    user_uuid,
    building_uuid,
    contact_uuid,
  }: CreateContactsArgs): Observable<AjaxResponse<CreateContactsSuccessResponse>> {
    return ajax.post(
      `${this.baseUrl}/api/tenant/v1/buildings/${building_uuid}/users/${user_uuid}/contacts`,
      { contacts: [contact_uuid] },
      this.DefaultHeaders,
    );
  }

  deleteContact({ user_uuid, building_uuid, contact_uuid }: DeleteContactArgs): Observable<AjaxResponse<null>> {
    return ajax.delete(
      `${this.baseUrl}/api/tenant/v1/buildings/${building_uuid}/users/${user_uuid}/contacts/${contact_uuid}`,
      this.DefaultHeaders,
    );
  }

  getContacts({
    full_name,
    building_uuid,
    user_uuid,
  }: GetContactsArgs): Observable<AjaxResponse<GetContactsSuccessResponse>> {
    const params = qs.stringify(
      {
        full_name,
        employees: false,
        sort: 'first_name',
      },
      {
        skipNulls: true,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        encode: false,
      },
    );
    return ajax.get(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/buildings/${building_uuid}/users/${user_uuid}/contacts${params}`,
      this.DefaultHeaders,
    );
  }

  getEmployees({
    building_uuid,
    user_uuid,
    employees = true,
    sort = 'first_name',
    full_name,
    offset,
    limit,
  }: GetEmployeesArgs): Observable<AjaxResponse<GetContactsSuccessResponse>> {
    const params = qs.stringify(
      {
        employees,
        sort,
        full_name,
        offset,
        limit,
      },
      {
        skipNulls: true,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        encode: false,
      },
    );
    return ajax.get(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/buildings/${building_uuid}/users/${user_uuid}/contacts${params}`,
      this.DefaultHeaders,
    );
  }

  getTeammates({
    building_uuid,
    user_uuid,
    sort = 'first_name',
    full_name,
    offset,
    limit,
  }: GetEmployeesArgs): Observable<AjaxResponse<GetContactsSuccessResponse>> {
    const params = qs.stringify(
      {
        sort,
        full_name,
        offset,
        limit,
      },
      {
        skipNulls: true,
        arrayFormat: 'comma',
        addQueryPrefix: true,
        encode: false,
      },
    );
    return ajax.get(
      // eslint-disable-next-line max-len
      `${this.baseUrl}/api/tenant/v1/buildings/${building_uuid}/users/${user_uuid}/contacts${params}`,
      this.DefaultHeaders,
    );
  }
}
