/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const Minus: React.FC<IconContentProps> = ({
  dataCy, className, variant, size, ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill=""
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_1844_13661" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1844_13661)">
      <circle cx="12" cy="12" r="12" fill="#EAECEE" />
      <mask id="mask1_1844_13661" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#F6F7F8" />
      </mask>
      <g mask="url(#mask1_1844_13661)">
        <path d="M5 13V11H19V13H5Z" fill="#696E72" />
      </g>
    </g>
  </SvgIcon>
);

export default Minus;
