/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { IconContentProps, SvgIcon } from '@hqo/react-components-library/dist/icons';

import React from 'react';

export const Groups: React.FC<IconContentProps> = ({
  dataCy,
  className,
  variant,
  size,
  ...svgProps
}: IconContentProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="12"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variant={variant}
    size={size}
    data-cy={dataCy}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...svgProps}
  >
    <path
      d="M0 12V10.425C0 9.69167 0.366667 9.104 1.1 8.662C1.83333 8.22067 2.8 8 4 8C4.21667 8 4.421 8.00833 4.613 8.025C4.80433 8.04167 4.99167 8.06667 5.175 8.1C4.94167 8.43333 4.77067 8.79167 4.662 9.175C4.554 9.55833 4.5 9.96667 4.5 10.4V12H0ZM6 12V10.4C6 9.31667 6.55433 8.43733 7.663 7.762C8.771 7.08733 10.2167 6.75 12 6.75C13.8 6.75 15.25 7.08733 16.35 7.762C17.45 8.43733 18 9.31667 18 10.4V12H6ZM19.5 12V10.4C19.5 9.96667 19.4417 9.55833 19.325 9.175C19.2083 8.79167 19.0417 8.43333 18.825 8.1C19.0083 8.06667 19.196 8.04167 19.388 8.025C19.5793 8.00833 19.7833 8 20 8C21.2 8 22.1667 8.22067 22.9 8.662C23.6333 9.104 24 9.69167 24 10.425V12H19.5ZM4 7C3.45 7 2.97933 6.804 2.588 6.412C2.196 6.02067 2 5.55 2 5C2 4.45 2.196 3.979 2.588 3.587C2.97933 3.19567 3.45 3 4 3C4.55 3 5.02067 3.19567 5.412 3.587C5.804 3.979 6 4.45 6 5C6 5.55 5.804 6.02067 5.412 6.412C5.02067 6.804 4.55 7 4 7ZM20 7C19.45 7 18.979 6.804 18.587 6.412C18.1957 6.02067 18 5.55 18 5C18 4.45 18.1957 3.979 18.587 3.587C18.979 3.19567 19.45 3 20 3C20.55 3 21.021 3.19567 21.413 3.587C21.8043 3.979 22 4.45 22 5C22 5.55 21.8043 6.02067 21.413 6.412C21.021 6.804 20.55 7 20 7ZM12 6C11.1667 6 10.4583 5.70833 9.875 5.125C9.29167 4.54167 9 3.83333 9 3C9 2.16667 9.29167 1.45833 9.875 0.875C10.4583 0.291667 11.1667 0 12 0C12.8333 0 13.5417 0.291667 14.125 0.875C14.7083 1.45833 15 2.16667 15 3C15 3.83333 14.7083 4.54167 14.125 5.125C13.5417 5.70833 12.8333 6 12 6Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Groups;
