import React, { useEffect, useMemo, useState } from 'react';
import { UseContactsResponse, useContacts } from './close-colleagues/useContacts.hook';
import { selectUserRegistrations, selectUserRegistrationsStatus } from '../../store/registrations/selectors';

import { ACTION_STATUSES } from '../../shared/consts/action-statuses';
import { RootState } from '../../store/reducer';
import { StyledPeopleIcon } from 'pages/calendar/styles';
import { currentUser } from '../../store/user/selectors';
import moment from 'moment-timezone';
import { usePrevious } from '../../hooks/use-previous.hook';
import { useSelector } from 'react-redux';

const COMPLETED_STATUSES = [ACTION_STATUSES.FULFILLED, ACTION_STATUSES.REJECTED];

const getStartOfWeek = (startingDate: string) => {
  const currentIsoDay = moment(startingDate).isoWeekday();
  const isWeekend = currentIsoDay > 5;
  if (isWeekend) {
    // eslint-disable-next-line newline-per-chained-call
    return moment(startingDate).add(2, 'days').startOf('isoWeek').toDate().toUTCString();
  }
  return moment(startingDate).startOf('isoWeek').toDate().toUTCString();
};

export const generateInitialDates = (startDate: string, startingWeekday = 1, dayCount = 5): string[] =>
  Array(dayCount)
    .fill(moment(startDate))
    .map((date, index) =>
      moment(date)
        .add(moment().day() - moment().day() + index + startingWeekday, 'days')
        .toString(),
    );

export const useRegistrations = (selectedDate?: string) => {
  const user = useSelector(currentUser);
  const { contacts }: UseContactsResponse = useContacts({ searchValue: null });
  const currentUserRegistrations = useSelector((state: RootState) => selectUserRegistrations(state, user?.uuid));
  const currentUserRegistrationsStatus = useSelector((state: RootState) =>
    selectUserRegistrationsStatus(state, user?.uuid),
  );
  const previousUserRegistrationStatus = usePrevious(currentUserRegistrationsStatus);
  const [isInitalFetch, setIsInitialFetch] = useState(true);
  const initialDates = useMemo(() => generateInitialDates(getStartOfWeek(selectedDate), 0), [selectedDate]);
  // This is used for the initial loader when we are making the initial registrations call
  useEffect(() => {
    const previousIsLoading = previousUserRegistrationStatus === ACTION_STATUSES.PENDING;
    const currentIsComplete = COMPLETED_STATUSES.includes(currentUserRegistrationsStatus);
    if (previousIsLoading && currentIsComplete && isInitalFetch) {
      setIsInitialFetch(false);
    }
  }, [previousUserRegistrationStatus, currentUserRegistrationsStatus]);

  const selectedDaysData = useMemo(
    () =>
      initialDates.map((date) => {
        const registrationDate = currentUserRegistrations.find((registration) =>
          moment(registration.date).isSame(date, 'day'),
        );
        return {
          date,
          isSelected: !!registrationDate?.uuid,
          displayValue: Number(registrationDate?.teammate_count ?? 0).toString(),
          icon: <StyledPeopleIcon />,
        };
      }),
    [currentUserRegistrations, initialDates],
  );

  const selectedDaysContacts = useMemo(
    () =>
      currentUserRegistrations.find((registration) => moment(registration.date).isSame(selectedDate, 'day'))
        ?.contacts || [],
    [selectedDate, currentUserRegistrations],
  );

  const selectedDayUserRegistration = useMemo(
    () => selectedDaysData.find((dayData) => moment(dayData.date).isSame(selectedDate, 'day'))?.isSelected,
    [selectedDate, selectedDaysData],
  );

  const hasContacts = contacts.length > 0;

  return {
    currentUserRegistrationsStatus,
    dates: initialDates,
    selectedDaysData,
    selectedDaysContacts,
    isInitalFetch,
    previousUserRegistrationStatus,
    getStartOfWeek,
    selectedDayUserRegistration,
    hasContacts,
  };
};
